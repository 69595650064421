import { View, TouchableOpacity, ActivityIndicator } from 'react-native'
import {
  Spacer,
  ComponentOptions,
  Icon,
  AppColors,
  Text,
} from '@gotradie/gt-components'
import { Clip } from '../../types/Files'
import { Constants, Util } from '../../common'
import { useActiveEffectiveUserId } from '../../context/UserContext'
import ClipPreview from '../ClipPreview'
import {
  ClipCentralButtonActionTypes,
  ClipDeleteProgress,
  ClipLayout,
  ClipPermissions,
} from '../../types/ClipCentral'
import StyleSheet from 'react-native-media-query'
import useScreenType from '../../common/DimensionsUtil'
import { ScreenType } from '../../types/Common'
import ClipPreviewHighlightView from './ClipPreviewHighlightView'
import ClipUtil from '../../common/ClipUtil'
import { useEffect, useState } from 'react'
import { useDeleteClip } from '../../hooks/clip/useMutateClip'
import WarningModal from '../WarningModal'
import { TaskBuilder } from '../../prototypes/TodoList'

const { styles } = StyleSheet.create({
  wrapperStyle: {
    flex: 1,
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  footerContainerStyle: {
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.LARGE,
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  clipPreviewButtonsContainerStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingHorizontal: ComponentOptions.SPACES.LARGE,
  },
  clipPreviewContainerStyle: {
    alignItems: 'center',
    flex: 1,
  },
  headerContainerStyle: {
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'flex-end',
    width: '100%',
  },
  headerContainerSlideModeStyle: {
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    justifyContent: 'space-between',
    width: '100%',
  },
  clipPreviewContainerSlidesStyle: {
    alignItems: 'flex-start',
    flex: 1,
    paddingLeft: ComponentOptions.SPACES.MEDIUM,
  },
  clipHeaderTopMenuRightStyle: { flexDirection: 'row' },
  clipHeaderTopMenuLeftStyle: {
    flexDirection: 'column',
    maxWidth: '70%',
  },
  clipHeaderTopMenuLeftMobileStyle: {
    flexDirection: 'column',
    maxWidth: '70%',
    overflow: 'hidden',
  },
})

type ClipPreviewButtonProps = {
  onPress: () => void
  iconName: string
  title: string
  isDisabled: boolean
  isRemove?: boolean
}

const ClipPreviewButton = (props: ClipPreviewButtonProps) => {
  const { onPress, iconName, title, isDisabled, isRemove } = props

  const disabledColor = isDisabled
    ? AppColors.common.grey
    : AppColors.common.white

  return (
    <TouchableOpacity
      style={styles.clipPreviewButtonsContainerStyle}
      disabled={isDisabled}
    >
      {isDisabled && isRemove ? (
        <ActivityIndicator color={disabledColor} />
      ) : (
        <Icon
          name={iconName}
          size={22}
          fill={disabledColor}
          stroke={1}
          onPress={onPress}
        />
      )}
      <Spacer size={ComponentOptions.SPACES.SMALL} />
      <Text body_small color={disabledColor}>
        {title}
      </Text>
    </TouchableOpacity>
  )
}

type ClipPreviewFooterProps = {
  onPress: (action: ClipCentralButtonActionTypes) => void
  onPressDelete: (clipId: string) => void
  clipPermissions: ClipPermissions
  clipDeleteProgress?: ClipDeleteProgress
  clipObj: Clip
}

const ClipPreviewFooter = (props: ClipPreviewFooterProps) => {
  const {
    onPress,
    onPressDelete,
    clipPermissions,
    clipDeleteProgress,
    clipObj,
  } = props

  const isDisabled =
    clipDeleteProgress?.clipId === clipObj.clipId
      ? clipDeleteProgress?.isDeleting
      : false

  return (
    <View style={styles.footerContainerStyle}>
      {clipPermissions?.addMore && (
        <ClipPreviewButton
          title={`Add more`}
          iconName={'AddImage'}
          onPress={() => onPress(ClipCentralButtonActionTypes.AddMore)}
          isDisabled={isDisabled as boolean}
        />
      )}

      {clipPermissions?.edit && (
        <ClipPreviewButton
          title={`Edit clip`}
          iconName={'EditClip'}
          onPress={() => onPress(ClipCentralButtonActionTypes.EditClip)}
          isDisabled={isDisabled as boolean}
        />
      )}

      {clipPermissions?.delete && (
        <ClipPreviewButton
          title={`Remove`}
          iconName={'Bin'}
          onPress={() => onPressDelete(clipObj.clipId)}
          isDisabled={isDisabled as boolean}
          isRemove={true}
        />
      )}

      {clipPermissions?.share && (
        <ClipPreviewButton
          title={`Link`}
          iconName={'Copy'}
          onPress={() => onPress(ClipCentralButtonActionTypes.CopyLink)}
          isDisabled={isDisabled as boolean}
        />
      )}

      {clipPermissions?.send && (
        <ClipPreviewButton
          title={`Send`}
          iconName={'Send'}
          onPress={() => onPress(ClipCentralButtonActionTypes.Send)}
          isDisabled={isDisabled as boolean}
        />
      )}
    </View>
  )
}

type ClipPreviewHeaderProps = {
  currentLayout: ClipLayout
  onPressClose: () => void
  onPressChangeLayout: (layout: ClipLayout) => void
  clipObj: Clip
}
const ClipPreviewHeader = (props: ClipPreviewHeaderProps) => {
  const { currentLayout, onPressClose, onPressChangeLayout, clipObj } = props

  const isDesktopView = useScreenType() === ScreenType.Desktop

  return (
    <View
      style={[
        styles.headerContainerStyle,
        currentLayout === ClipLayout.slides
          ? styles.headerContainerSlideModeStyle
          : {},
      ]}
    >
      {currentLayout === ClipLayout.slides && (
        <View
          style={
            isDesktopView
              ? styles.clipHeaderTopMenuLeftStyle
              : styles.clipHeaderTopMenuLeftMobileStyle
          }
        >
          {clipObj.location && clipObj.location.name !== '' && (
            <Text body_small color={AppColors.common.white}>
              {clipObj.location?.address}
            </Text>
          )}
          <Text body_strong color={AppColors.common.white}>
            {clipObj.clipTitle}
          </Text>
          {clipObj.clipDescription !== '' && (
            <Text body_small color={AppColors.common.white}>
              {clipObj.clipDescription}
            </Text>
          )}
        </View>
      )}

      <View style={styles.clipHeaderTopMenuRightStyle}>
        <Icon
          name={currentLayout === ClipLayout.highlight ? 'Tools' : 'Highlights'}
          size={22}
          fill={AppColors.common.white}
          stroke={1}
          onPress={() =>
            onPressChangeLayout(
              currentLayout === ClipLayout.highlight
                ? ClipLayout.slides
                : ClipLayout.highlight
            )
          }
        />
        <Spacer isVertical size={ComponentOptions.SPACES.LARGE} />
        <Icon
          name={'Close'}
          size={22}
          fill={AppColors.common.white}
          stroke={1}
          onPress={onPressClose}
        />
      </View>
    </View>
  )
}

type ClipCentralPreviewProps = {
  businessEffectiveUserId: string
  clipObj: Clip
  onPressClose: () => void
  onPressActionButton: (action: ClipCentralButtonActionTypes) => void
  currentLayout: ClipLayout
  setCurrentLayout: (layout: ClipLayout) => void
  clipDeletingProgress?: ClipDeleteProgress
  deletedClipId?: string
}

const ClipCentralPreview = (props: ClipCentralPreviewProps) => {
  const {
    clipObj,
    onPressClose,
    onPressActionButton,
    currentLayout,
    setCurrentLayout,
    clipDeletingProgress,
    deletedClipId,
  } = props
  const screenType = useScreenType()

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [deletingClipId, setDeletingClipId] = useState<string>('')
  const [selectedMediaIndex, setSelectedMediaIndex] = useState<number>(0)

  useEffect(() => {
    setSelectedMediaIndex(0)
  }, [clipObj])

  const businessEffectiveUserId = useActiveEffectiveUserId()

  const { mutate: deleteClip, isLoading: isDeleting } = useDeleteClip(
    onSuccessDeleteClip,
    onErrorDeleteClip
  )

  function onSuccessDeleteClip() {
    Util.showSuccessMessage('Clip deleted')
    onPressClose()
  }

  function onErrorDeleteClip(error: any) {
    Util.showErrorMessage(
      error?.response?.data?.message || 'Error on deleting clip'
    )
  }

  const clipPermissions = ClipUtil.filterUserClipPermissions(
    businessEffectiveUserId,
    clipObj?.clipPermissions
  )

  if (deletedClipId === clipObj.clipId && clipDeletingProgress?.isDeleted) {
    onPressClose()
  }

  if (clipObj) {
    const onPress = (action: ClipCentralButtonActionTypes) => {
      if (action === ClipCentralButtonActionTypes.CopyLink) {
        Util.copyToClipboard(
          clipObj.clipUrl,
          () => {
            Util.showSuccessMessage(
              'Copied link to clipboard',
              Constants.TIMEOUT.DEFAULT * 3
            )
          },
          (er) => {
            Util.showErrorMessage(er)
          }
        )
      } else {
        onPressActionButton(action)
      }
    }

    const getClipDimensions = (screenTypeVal: ScreenType) => {
      let width: any = 375
      let height: any = 675

      if (screenTypeVal === ScreenType.Desktop) {
        width = 375
        height = '90%'
      } else if (screenTypeVal === ScreenType.Mobile) {
        width = '90%'
        height = '90%'
      }

      return { width, height }
    }

    const { width, height } = getClipDimensions(screenType)
    return (
      <View style={styles.wrapperStyle}>
        <View
          style={[
            styles.clipPreviewContainerStyle,
            currentLayout === ClipLayout.slides
              ? styles.clipPreviewContainerSlidesStyle
              : {},
          ]}
        >
          <ClipPreviewHeader
            onPressChangeLayout={setCurrentLayout}
            currentLayout={currentLayout}
            onPressClose={() => {
              setCurrentLayout(ClipLayout.slides)
              setSelectedMediaIndex(0)
              onPressClose()
            }}
            clipObj={clipObj}
          />
          {currentLayout === ClipLayout.highlight && (
            <ClipPreview
              clipObj={clipObj}
              height={height}
              width={width}
              selectedMediaIndex={selectedMediaIndex}
            />
          )}
          {currentLayout === ClipLayout.slides && (
            <ClipPreviewHighlightView
              clipObj={clipObj}
              setSelectedMediaIndex={(index) => {
                setSelectedMediaIndex(index)
                setCurrentLayout(ClipLayout.highlight)
              }}
            />
          )}
        </View>

        <ClipPreviewFooter
          onPress={onPress}
          onPressDelete={(clipId) => {
            setShowDeleteModal(true)
            setDeletingClipId(clipId)
          }}
          clipPermissions={clipPermissions as ClipPermissions}
          clipDeleteProgress={{
            clipId: deletedClipId || deletingClipId,
            isDeleting: clipDeletingProgress?.isDeleting || isDeleting,
          }}
          clipObj={clipObj}
        />

        <WarningModal
          isVisible={showDeleteModal}
          title={'Remove entire clip?'}
          subTitle={
            'If this clips is shared, collaborators can no longer view or edit this clips. It will be permanently deleted.'
          }
          onAccept={() => {
            setShowDeleteModal(false)
            deleteClip({
              clipId: clipObj.clipId,
              threadId: clipObj.threadId,
            })
          }}
          onCancel={() => setShowDeleteModal(false)}
          acceptButtonProp={{ buttonColor: AppColors.common.red }}
        />
      </View>
    )
  }
  return null
}

export default ClipCentralPreview
