import { useEffect } from 'react'
import { View, ActivityIndicator } from 'react-native'
import StyleSheet from 'react-native-media-query'
import { Text, Spacer, AppColors } from '@gotradie/gt-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useFetchInvitation } from '../hooks/invitation/useFetchInvitation'
import { Constants, Util } from '../common'
import { InvitationType, MagicLink } from '../types/Invitation'
import NavigationKeys from '../navigation/NavigationKeys'
import { useFetchMyProfile } from '../hooks/user/useFetchUser'
import { useInviteMagicLink } from '../hooks/invitation/useMutateInvitation'
import { PageRedirectTypes, ServerError } from '../types/Common'
import {
  useActiveEffectiveUserId,
  useActiveRoleType,
} from '../context/UserContext'
import { RoleType } from '../types/User'
import { generateDynamicLinkShortUrl } from '../hooks/common/useMutateCommon'

const { styles } = StyleSheet.create({
  centerContainer: {
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
})

const MagicLinkAuthenticated = () => {
  const navigate = useNavigate()
  const params = useParams()
  const InvitationId = params?.id

  const activeRoleType = useActiveRoleType()
  const activeEffectiveUserId = useActiveEffectiveUserId()
  const { data: profile } = useFetchMyProfile(true)

  const canShowThread =
    activeRoleType === RoleType.tradieOwner ||
    activeRoleType === RoleType.tradieAdmin ||
    activeRoleType === RoleType.tradieWorker ||
    activeRoleType === RoleType.seekerRole

  const isSeeker = activeRoleType === RoleType.seekerRole

  const { data } = useFetchInvitation(
    { id: InvitationId || '', invitationType: InvitationType.MAGIC_LINK },
    true,
    (er) => {
      Util.showErrorMessage(er.message || '')
      navigate(NavigationKeys.HOME)
    }
  )

  const { mutate: onSendInvite } = useInviteMagicLink(onSuccessInvite, onError)
  const { mutate: generateDynamicLink } = generateDynamicLinkShortUrl(
    onSuccessGenerateDynamicLink,
    onError
  )

  useEffect(() => {
    if (!!data && !!profile) {
      if (canShowThread) {
        const magicLinkData = data as MagicLink
        onSendInvite({
          phoneNumber: profile?.phoneNumber,
          magicToken: InvitationId as string,
          threadId: magicLinkData.threadId || '',
          threadType: isSeeker
            ? Constants.THREAD_TYPE.GENERAL
            : magicLinkData?.threadInfo?.threadType || '',
          isseeker: isSeeker,
        })
      } else {
        redirectToMagicLinkPendingInvite()
      }
    }
  }, [data, profile])

  function onSuccessInvite() {
    const magicLinkData = data as MagicLink

    if (isSeeker) {
      navigate(
        `${NavigationKeys.DEFAULT_REDIRECT}?to=${encodeURIComponent(
          `${NavigationKeys.GENERAL}/${magicLinkData.threadId}`
        )}`
      )
      return
    }

    const chatUrl = `${NavigationKeys.DEFAULT_REDIRECT}?type=${
      PageRedirectTypes.thread
    }&tt=${magicLinkData.threadInfo?.threadType || ''}&id=${
      magicLinkData.threadId
    }&efuid=${activeEffectiveUserId}`

    generateDynamicLink({
      link: `${process.env.WEB_APP_URL}${chatUrl}`,
    })
  }

  function onSuccessGenerateDynamicLink(res: any) {
    window.location.replace(res?.data?.shortLink)
  }

  function onError(er: ServerError) {
    Util.showErrorMessage(er.message || '')
    navigate(NavigationKeys.HOME)
  }

  function redirectToMagicLinkPendingInvite() {
    navigate(`${NavigationKeys.MAGIC_LINK_PENDING_WORKER}/${InvitationId}`)
  }

  return (
    <View style={styles.centerContainer}>
      <Text body color={AppColors.common.white}>
        {canShowThread ? `Redirecting to the thread..` : 'Redirecting...'}
      </Text>
      <Spacer />
      <ActivityIndicator color={AppColors.common.white} />
    </View>
  )
}

export default MagicLinkAuthenticated
