import { useCallManagerContext } from '../../context/CallManagerContext'
import { useCallStateHooks } from '@stream-io/video-react-sdk'
import { ComponentOptions, AppColors } from '@gotradie/gt-components'
import CallLobby from './CallLobby'
import { useFetchMyProfile } from '../../hooks/user/useFetchUser'
import { useEffect, useRef, useState } from 'react'
import { Util } from '../../common'
import CallPreview from './CallPreview'

const styles = {
  wrapper: {
    position: 'absolute' as const,
    opacity: 1,
    width: window.innerWidth - ComponentOptions.SPACES.MEDIUM,
    height: window.innerHeight - ComponentOptions.SPACES.MEDIUM,
    top: ComponentOptions.SPACES.SMALL,
    left: ComponentOptions.SPACES.SMALL,
    borderRadius: ComponentOptions.SPACES.MEDIUM,
    overflow: 'hidden',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.2)',
    transition: 'all 0.1s ease',
  },
  minimizedStyles: {
    width: 200,
    height: 300,
    left: window.innerWidth - 250,
    top: window.innerHeight - 350,
  },
}
const CallWrapper = () => {
  const isTogglingMic = useRef(false)
  const isTogglingCamera = useRef(false)
  const disableJoin = useRef(false)
  const isPressedContainer = useRef(false)

  const { data: profile } = useFetchMyProfile()
  const { callState, call, setCall, setCallState } = useCallManagerContext()
  const { useCameraState, useMicrophoneState } = useCallStateHooks()
  const { camera, mediaStream, isEnabled: cameraEnabled } = useCameraState()
  const { microphone, isEnabled: micEnabled } = useMicrophoneState()

  const [deviceError, setDeviceError] = useState({ mic: null, camera: null })
  const [isJoiningCall, setJoiningCall] = useState(false)
  const [isMinimized, setIsMinimized] = useState(false)

  useEffect(() => {
    initiateCallActions()
  }, [])

  document.addEventListener('mousemove', (e) => {
    Util.dragThisItem(
      e.clientX,
      e.clientY,
      'CallPreviewWrapper',
      isPressedContainer.current
    )
    e.preventDefault()
  })

  document.addEventListener('touchmove', (e) => {
    Util.dragThisItem(
      e.touches?.[0]?.clientX,
      e.touches?.[0]?.clientY,
      'CallPreviewWrapper',
      isPressedContainer.current
    )
    e.preventDefault()
  })

  document.addEventListener('mouseup', () => {
    if (isPressedContainer.current) isPressedContainer.current = false
  })

  document.addEventListener('touchend', () => {
    if (isPressedContainer.current) isPressedContainer.current = false
  })

  async function initiateCallActions() {
    isTogglingCamera.current = false
    isTogglingMic.current = false
    await toggleMic().catch(() => Util.showErrorMessage('Failed to toggle mic'))
    await toggleVideo().catch(() =>
      Util.showErrorMessage('Failed to toggle video')
    )
  }

  async function toggleMic() {
    if (isTogglingMic.current) return
    isTogglingMic.current = true
    await microphone
      .toggle()
      .then(
        () =>
          !!deviceError.mic &&
          setDeviceError((prevState) => ({ ...prevState, mic: null }))
      )
      .catch((e) => {
        setDeviceError((prevState) => ({ ...prevState, mic: e }))
      })
      .finally(() => {
        isTogglingMic.current = false
      })
  }

  async function toggleVideo() {
    if (isTogglingCamera.current) return
    isTogglingCamera.current = true
    await camera
      .toggle()
      .then(
        () =>
          !!deviceError.camera &&
          setDeviceError((prevState) => ({ ...prevState, camera: null }))
      )
      .catch((e) =>
        setDeviceError((prevState) => ({ ...prevState, camera: e }))
      )
      .finally(() => {
        isTogglingCamera.current = false
      })
  }

  async function startCall() {
    try {
      setJoiningCall(true)
      if (callState.isCreator) {
        await call?.getOrCreate({ ring: true })
        await call?.join({ create: false })
      } else {
        await call?.join()
      }

      setCallState({ ...callState, ...{ initiatingCall: false, inCall: true } })
      call && setCall(call)
    } catch (e) {
      Util.showErrorMessage('Failed to start call')
    } finally {
      setJoiningCall(false)
    }
  }

  async function leaveCall() {
    disableJoin.current = true
    micEnabled && (await microphone?.disable())
    cameraEnabled && (await camera?.disable())
    await call
      ?.leave()
      .catch((e) => console.log('Failed to leave call', e))
      .finally(() => {
        disableJoin.current = false
        setCall(null)
        setCallState({
          callMembers: [],
          initiatingCall: false,
          joiningCall: false,
          inCall: false,
          isCreator: false,
          callType: undefined,
        })
      })
  }

  function enableDragging() {
    if (isMinimized) {
      isPressedContainer.current = true
    } else {
      isPressedContainer.current = false
    }
  }

  return (
    <div
      id="CallPreviewWrapper"
      style={{
        ...styles.wrapper,
        ...{
          backgroundColor: callState.inCall
            ? AppColors.common.tertiary
            : AppColors.common.white,
        },
        ...(isMinimized && styles.minimizedStyles),
      }}
      onMouseDown={enableDragging}
      onTouchStart={enableDragging}
    >
      {callState.initiatingCall && (
        <CallLobby
          profile={profile}
          isJoiningCall={isJoiningCall}
          deviceError={deviceError}
          myCameraEnabled={cameraEnabled}
          myMicEnabled={micEnabled}
          myMediaStream={mediaStream}
          callState={callState}
          toggleMic={toggleMic}
          toggleVideo={toggleVideo}
          startCall={startCall}
          leaveCall={leaveCall}
        />
      )}
      {callState.inCall && (
        <CallPreview
          profile={profile}
          myMicEnabled={micEnabled}
          myCameraEnabled={cameraEnabled}
          disableJoin={disableJoin.current}
          deviceError={deviceError}
          isMinimized={isMinimized}
          toggleMic={toggleMic}
          toggleVideo={toggleVideo}
          leaveCall={leaveCall}
          setIsMinimized={setIsMinimized}
        />
      )}
    </div>
  )
}

export default CallWrapper
