import { View, StyleSheet, TouchableOpacity } from 'react-native'
import {
  ComponentOptions,
  AppColors,
  Spacer,
  Icon,
  Text,
} from '@gotradie/gt-components'
import { Profile } from '../../types/User'
import ActionButton from './ActionButton'
import { Util } from '../../common'
import { useCallback, useEffect, useState } from 'react'
import {
  Audio,
  StreamVideoParticipant,
  useCallStateHooks,
} from '@stream-io/video-react-sdk'
import ParticipantsVideosList from './ParticipantsVideosList'
import LocalParticipantPreview from './LocalParticipantPreview'
import OtherParticipantPreview from './OtherParticipantPreview'

const styles = StyleSheet.create({
  previewsWrapper: {
    width: '100%',
    height: 'calc(100% - 60px)',
    position: 'relative',
    flexDirection: 'row',
    overflow: 'hidden',
    backgroundColor: AppColors.common.black,
  },
  mainPreviewWrapper: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  otherParticipantListPreviewWrapper: {
    width: '190px',
    height: '100px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: ComponentOptions.SPACES.SMALL,
    borderRadius: ComponentOptions.SPACES.SMALL,
    backgroundColor: AppColors.common.tertiary,
  },
  previewlistWrapper: {
    width: '200px',
    height: '100%',
    borderLeftColor: AppColors.common.dark,
    borderLeftWidth: 1,
    justifyContent: 'center',
  },
  actionButtonsWrapper: {
    flexDirection: 'row',
    flex: 1,
    bottom: 0,
    width: '100%',
    height: '60px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: AppColors.common.tertiary,
  },
  showMemberIconWrapper: {
    position: 'absolute',
    right: ComponentOptions.SPACES.MEDIUM,
    top: ComponentOptions.SPACES.MEDIUM,
    padding: ComponentOptions.SPACES.EXTRA_SMALL,
    borderRadius: ComponentOptions.SPACES.EXTRA_SMALL,
    backgroundColor: AppColors.common.modalBackDrop,
  },
  resizeIconWrapper: {
    position: 'absolute',
    left: ComponentOptions.SPACES.MEDIUM,
    top: ComponentOptions.SPACES.MEDIUM,
    padding: ComponentOptions.SPACES.EXTRA_SMALL,
    borderRadius: ComponentOptions.SPACES.EXTRA_SMALL,
    backgroundColor: AppColors.common.modalBackDrop,
  },
  minimizedResizedIconStyles: {
    transform: [{ scaleX: -1 }],
  },
  mainVideoCustomStyles: {
    backgroundColor: AppColors.common.black,
    borderRadius: 0,
  },
  participantTextStyles: {
    marginLeft: ComponentOptions.SPACES.EXTRA_SMALL,
    marginTop: ComponentOptions.SPACES.MEDIUM,
  },
})

type BottomActionButtonsProps = {
  myMicEnabled: boolean
  myCameraEnabled: boolean
  deviceError: { mic: null | any; camera: null | any }
  disableJoin: boolean
  space?: number
  toggleMic: () => void
  toggleVideo: () => void
  leaveCall: () => void
}

const BottomActionButtons = ({
  myMicEnabled,
  myCameraEnabled,
  deviceError,
  disableJoin,
  space,
  toggleMic,
  toggleVideo,
  leaveCall,
}: BottomActionButtonsProps) => {
  return (
    <View style={styles.actionButtonsWrapper}>
      <ActionButton
        enabled={myMicEnabled}
        deviceError={!!deviceError.mic}
        actionDisabled={false}
        enableIcon={'DetailedMicOn'}
        disableIcon={'DetailedMicOff'}
        toggleAction={toggleMic}
        errorAction={() => Util.showWarningMessage('Device not found')}
      />
      <Spacer isVertical size={space || ComponentOptions.SPACES.LARGE} />
      <ActionButton
        enabled={myCameraEnabled}
        deviceError={!!deviceError.camera}
        actionDisabled={false}
        enableIcon={'DetailedVideoOn'}
        disableIcon={'DetailedVideoOff'}
        toggleAction={toggleVideo}
        errorAction={() => Util.showWarningMessage('Device not found')}
      />
      <Spacer isVertical size={space || ComponentOptions.SPACES.LARGE} />
      <ActionButton
        enabled={!disableJoin}
        deviceError={false}
        actionDisabled={false}
        enableIcon={'DetailedHangUp'}
        disableIcon={'DetailedHangUp'}
        color={AppColors.common.red}
        toggleAction={leaveCall}
        errorAction={() => window.location.reload()}
      />
    </View>
  )
}

type CallPreviewProps = {
  profile?: Profile
  disableJoin: boolean
  deviceError: { mic: null | any; camera: null | any }
  myMicEnabled: boolean
  myCameraEnabled: boolean
  isMinimized: boolean
  toggleMic: () => void
  toggleVideo: () => void
  leaveCall: () => void
  setIsMinimized: (isMinimized: boolean) => void
}

const CallPreview = ({
  profile,
  disableJoin,
  deviceError,
  myMicEnabled,
  myCameraEnabled,
  isMinimized,
  toggleMic,
  toggleVideo,
  leaveCall,
  setIsMinimized,
}: CallPreviewProps) => {
  const effectiveUserIds = profile?.effectiveUserId
  const { useCameraState, useParticipants } = useCallStateHooks()
  const { mediaStream: myMediaStream } = useCameraState()
  const participants = useParticipants()

  const otherParticipants = useCallback(() => {
    return participants?.filter((p) => p.userId !== effectiveUserIds)
  }, [participants, effectiveUserIds])

  const [highlightOtherParticipant, setHighlightOtherParticipant] =
    useState<StreamVideoParticipant | null>(null)
  const [isShowMembers, setIsShowMembers] = useState(false)

  useEffect(() => {
    if (otherParticipants()?.length === 0 || !gethighlightedParticipant()) {
      setHighlightOtherParticipant(null)
    }
  }, [otherParticipants])

  function gethighlightedParticipant() {
    if (!highlightOtherParticipant) {
      return null
    }

    return otherParticipants()?.find(
      (p) =>
        p.sessionId === highlightOtherParticipant?.sessionId &&
        p.userId === highlightOtherParticipant?.userId
    )
  }

  function highlightParticipant(selected: StreamVideoParticipant) {
    const participant: StreamVideoParticipant | undefined =
      otherParticipants()?.find(
        (p) =>
          p.sessionId === selected?.sessionId && p.userId === selected?.userId
      )
    setHighlightOtherParticipant(participant ? participant : null)
  }

  return (
    <>
      <View style={styles.previewsWrapper}>
        <View
          style={[
            styles.mainPreviewWrapper,
            {
              width:
                isShowMembers && !isMinimized ? 'calc(100% - 200px)' : '100%',
            },
          ]}
        >
          {otherParticipants()?.length > 0 &&
            otherParticipants()?.map((p) => {
              return (
                <Audio
                  key={p.userId}
                  participant={p}
                  style={{ display: 'none' }}
                />
              )
            })}
          {!!gethighlightedParticipant() ? (
            <OtherParticipantPreview
              id="streamVideo-Preview"
              participant={gethighlightedParticipant()}
              avatarsize={ComponentOptions.AVATAR_SIZE.LARGE}
            />
          ) : (
            <LocalParticipantPreview
              id="localVideo-Preview"
              avatarSize={ComponentOptions.AVATAR_SIZE.LARGE}
              localParticipantMicOn={myMicEnabled}
              profile={profile}
              myMediaStream={myMediaStream}
              styles={styles.mainVideoCustomStyles}
            />
          )}
        </View>
        <View
          style={[
            styles.previewlistWrapper,
            { width: isShowMembers ? '200px' : 0 },
          ]}
        >
          <Spacer size={ComponentOptions.SPACES.LARGEST} />
          {highlightOtherParticipant && (
            <TouchableOpacity
              style={styles.otherParticipantListPreviewWrapper}
              onPress={() => setHighlightOtherParticipant(null)}
            >
              <LocalParticipantPreview
                id="localVideo-memberList"
                avatarSize={ComponentOptions.AVATAR_SIZE.MEMBER_WIDGET}
                localParticipantMicOn={myMicEnabled}
                profile={profile}
                myMediaStream={myMediaStream}
                videoScale="scale(-1.1,1.1)"
              />
            </TouchableOpacity>
          )}
          {highlightOtherParticipant && (
            <View style={styles.participantTextStyles}>
              <Text body_small color={AppColors.common.white}>
                {'Other participants'}
              </Text>
            </View>
          )}
          <ParticipantsVideosList
            participants={otherParticipants() || []}
            mainPreviewParticipant={highlightOtherParticipant}
            onPressParticipant={highlightParticipant}
          />
        </View>
        {!isMinimized && (
          <View style={styles.showMemberIconWrapper}>
            <Icon
              name={'People'}
              size={ComponentOptions.SPACES.MEDIUM}
              fill={
                isShowMembers
                  ? AppColors.common.concrete
                  : AppColors.common.primary
              }
              onPress={() => setIsShowMembers(!isShowMembers)}
            />
          </View>
        )}
        <View
          style={[
            styles.resizeIconWrapper,
            isMinimized && styles.minimizedResizedIconStyles,
          ]}
        >
          <Icon
            name={isMinimized ? 'Expand' : 'MinimizeScreen'}
            size={ComponentOptions.SPACES.MEDIUM}
            fill={AppColors.common.primary}
            onPress={() => setIsMinimized(!isMinimized)}
          />
        </View>
      </View>
      <BottomActionButtons
        myMicEnabled={myMicEnabled}
        myCameraEnabled={myCameraEnabled}
        deviceError={deviceError}
        disableJoin={disableJoin}
        space={
          isMinimized
            ? ComponentOptions.SPACES.SMALL
            : ComponentOptions.SPACES.LARGE
        }
        toggleMic={toggleMic}
        toggleVideo={toggleVideo}
        leaveCall={leaveCall}
      />
    </>
  )
}

export default CallPreview
