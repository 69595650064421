import { CognitoUser } from 'amazon-cognito-identity-js'
import { useMutation } from 'react-query'
import { AuthUtil, getAxios } from '../../common'
import { ServerError } from '../../types/Common'
import { AxiosError } from 'axios'

const api = getAxios(false)

//-----------------LoginUser------------------
export type LoginUserParam = {
  username: string
  password: string
}
const loginUser = async (params: LoginUserParam) => {
  return await AuthUtil.loginUser(params.username, params.password)
}
export const useLoginUser = (
  onSuccessCallback: (data: any, variables: LoginUserParam) => void,
  onErrorCallback: (x: ServerError, variables: LoginUserParam) => void
) => {
  return useMutation(loginUser, {
    onSuccess: onSuccessCallback,
    onError: (error: any, variables: LoginUserParam) => {
      onErrorCallback(error, variables)
    },
  })
}

//-----------------Signup User------------------
export type SignUpUserParam = {
  username: string
  password: string
  uuid: string
}
const signUpUser = async (params: SignUpUserParam) => {
  return await AuthUtil.signUpUser(
    params.username,
    params.password,
    params.uuid
  )
}
export const useSignUpUser = (
  onSuccessCallback: (data: any, variables: SignUpUserParam) => void,
  onErrorCallback: (x: ServerError, variables: SignUpUserParam) => void
) => {
  return useMutation(signUpUser, {
    onSuccess: onSuccessCallback,
    onError: (error, variables) => {
      onErrorCallback(error as ServerError, variables as SignUpUserParam)
    },
  })
}

//-----------------SignUp confirm------------------
type SignUpUserConfirmParam = {
  username: string
  code: string
}
const signUpUserConfirm = async (params: SignUpUserConfirmParam) => {
  return await AuthUtil.signUpUserConfirm(params.username, params.code)
}
export const useSignUpUserConfirm = (
  onSuccessCallback: (data: any, variables: any) => void,
  onErrorCallback: (x: ServerError) => void
) => {
  return useMutation(signUpUserConfirm, {
    onSuccess: onSuccessCallback,
    onError: (error) => {
      onErrorCallback(error as ServerError)
    },
  })
}

//-----------------Resend code------------------
type SignUpResendCodeParam = {
  username: string
}
const signUpResendCode = async (params: SignUpResendCodeParam) => {
  return await AuthUtil.signUpUserResendVerificationCode(params.username)
}
export const useSignUpResendCode = (
  onSuccessCallback: () => void,
  onErrorCallback: (x: ServerError) => void
) => {
  return useMutation(signUpResendCode, {
    onSuccess: onSuccessCallback,
    onError: (error) => {
      onErrorCallback(error as ServerError)
    },
  })
}

//-----------------Update attribute------------------
type userAttrUpdateCognitoParam = {
  email?: string
  'custom:claimed-business'?: string
  'custom:business-handle'?: string
}
const userAttrUpdateCognito = async (params: userAttrUpdateCognitoParam) => {
  const user: CognitoUser = await AuthUtil.getCurrentUser()
  return await AuthUtil.userUpdateAttributeCognito(user, params)
}
export const useUserAttributeUpdateCognito = (
  onSuccessCallback?: (
    data: any,
    variables: userAttrUpdateCognitoParam
  ) => void,
  onErrorCallback?: (x: ServerError) => void
) => {
  return useMutation(userAttrUpdateCognito, {
    onSuccess: onSuccessCallback,
    onError: (error) => {
      onErrorCallback?.(error as ServerError)
    },
  })
}

//-----------------Reset password------------------
const resetPassword = async (username: string) => {
  return await AuthUtil.forgotPassword(username)
}
export const useResetPassword = (
  onSuccessCallback?: (data: any, variables: string) => void,
  onErrorCallback?: (x: ServerError) => void
) => {
  return useMutation(resetPassword, {
    onSuccess: onSuccessCallback,
    onError: (error) => {
      onErrorCallback?.(error as ServerError)
    },
  })
}

//-----------------Verify reset password------------------
export type verifyResetPasswordParams = {
  username: string
  password: string
  verificationCode: string
}

const verifyResetPassword = async (params: verifyResetPasswordParams) => {
  return await AuthUtil.forgotPasswordSubmit(
    params.username,
    params.password,
    params.verificationCode
  )
}
export const useVerifyResetPassword = (
  onSuccessCallback?: (data: any, variables: verifyResetPasswordParams) => void,
  onErrorCallback?: (x: ServerError) => void
) => {
  return useMutation(verifyResetPassword, {
    onSuccess: onSuccessCallback,
    onError: (error) => {
      onErrorCallback?.(error as ServerError)
    },
  })
}

/*==================== Verify phone number ====================*/
export type validateConfirmationParam = {
  phoneNumber: string
}
const validateConfirmation = async (params: validateConfirmationParam) => {
  const url = `/signup/validateconfirmation`

  const body = {
    'phone-number': params.phoneNumber,
  }
  return await api.post(url, body)
}

export const useValidateConfirmation = (
  onSuccessCallback?: (data: any, variables: validateConfirmationParam) => void,
  onErrorCallback?: (
    x: ServerError,
    variables: validateConfirmationParam
  ) => void
) => {
  return useMutation(validateConfirmation, {
    onSuccess: onSuccessCallback,
    onError: (error: AxiosError, variables: validateConfirmationParam) => {
      onErrorCallback?.(error.response?.data as ServerError, variables)
    },
  })
}
