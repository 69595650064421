import { View, Image } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  AppColors,
  ComponentOptions,
  GoTradieLogo,
  Spacer,
  Text,
} from '@gotradie/gt-components'
import { DefaultContentType } from '../types/Common'
import React from 'react'

const { styles } = StyleSheet.create({
  placeholderWrapperStyle: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingVertical: ComponentOptions.SPACES.LARGE,
    paddingHorizontal: ComponentOptions.SPACES.LARGE * 4,
    display: 'flex',
    '@media (max-width: 1024px)': {
      paddingVertical: ComponentOptions.SPACES.LARGE * 4,
      display: 'none',
    },
  },
  imageStyle: {
    width: 545,
    height: 435,
  },
})

//default content types

const DefaultType = () => {
  return (
    <React.Fragment>
      <GoTradieLogo width={252} height={30} />
      <Spacer size={ComponentOptions.SPACES.LARGER} />
      <Text
        body
        color={AppColors.common.subtitleText}
      >{`Welcome to GoTradie web version, just like the mobile app but with a bit more space to move. Send and recieve messages on here and like magin it will appear on your phone as well`}</Text>
    </React.Fragment>
  )
}
const ClipsType = () => {
  return (
    <React.Fragment>
      <Image
        source={require('../images/home-page-graphic.png')}
        style={styles.imageStyle}
        resizeMode={'contain'}
      />
      <Spacer size={ComponentOptions.SPACES.LARGER} />
      <Text
        body
        color={AppColors.common.subtitleText}
      >{`Welcome to clips`}</Text>
    </React.Fragment>
  )
}

const NotificationCentralType = () => {
  return (
    <React.Fragment>
      <Image
        source={require('../images/home-page-graphic.png')}
        style={styles.imageStyle}
        resizeMode={'contain'}
      />
      <Spacer size={ComponentOptions.SPACES.MEDIUM} />
      <GoTradieLogo width={252} height={30} />
      <Spacer size={ComponentOptions.SPACES.LARGER} />
      <Text
        body
        color={AppColors.common.subtitleText}
      >{`Welcome to notification central`}</Text>
    </React.Fragment>
  )
}

type DefaultContentProps = {
  type?: DefaultContentType
}

const DefaultContent = (props: DefaultContentProps) => {
  const { type } = props
  return (
    <View style={styles.placeholderWrapperStyle}>
      {!!type || (type === DefaultContentType.default && <DefaultType />)}
      {type && type === DefaultContentType.clips && <ClipsType />}
      {type && type === DefaultContentType.notificationCentral && (
        <NotificationCentralType />
      )}
    </View>
  )
}

export default DefaultContent
