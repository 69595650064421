import { Constants, Util } from '../../common'
import Avatar from '../../components/Avatar'
import { AppColors } from '@gotradie/gt-components'
import {
  SfuModels,
  StreamVideoParticipant,
  Video,
} from '@stream-io/video-react-sdk'
import ParticipantVideoInfo from './ParticipantVideoInfo'

type OtherParticipantPreviewProps = {
  id: string
  participant: StreamVideoParticipant | null | undefined
  avatarsize: number
  videoScale?: string
}

const OtherParticipantPreview = ({
  id,
  participant,
  avatarsize,
  videoScale,
}: OtherParticipantPreviewProps) => {
  return (
    <>
      {participant?.publishedTracks.includes(SfuModels.TrackType.VIDEO) ? (
        <Video
          disablePictureInPicture
          id={`${id}-${participant?.sessionId}-${participant?.userId}`}
          VideoPlaceholder={null}
          trackType="videoTrack"
          participant={participant}
          style={{
            width: '100%',
            height: '100%',
            transform: videoScale || 'scale(-1,1)',
          }}
        />
      ) : (
        <Avatar
          imageUri={participant?.image || ''}
          imageFetchType={Constants.IMAGE_FETCH_TYPE.PROFILE}
          avatarProps={{
            size: avatarsize,
            altText: Util.getAcronym(participant?.name || ''),
            uniqueId: participant?.userId || '',
            hasBorder: true,
            borderColor: AppColors.common.white,
          }}
        />
      )}
      {participant && <ParticipantVideoInfo participant={participant} />}
    </>
  )
}

export default OtherParticipantPreview
