import { useInfiniteQuery, useQuery } from 'react-query'
import { Constants, getAxios, QueryKeys } from '../../common'
import { useActiveOrgId } from '../../context/UserContext'
import {
  NotificationItem,
  NotificationReadStatus,
  NotificationScenario,
} from '../../types/Notification'
import { dtoToProfile } from '../user/useFetchUser'

const api = getAxios()

//-----------Get Notifications--------------

const fetchNotifications = async (pageParam = '', effectiveUserId = '') => {
  const pageSize = Constants.PAGE_SIZES.NOTIFICATIONS
  const URL = `/notifications?only-scenario-notifications=true&page-size=${pageSize}&start-key=${pageParam}&effective-user-id=${effectiveUserId}`
  return await api.get(URL)
}

export const useFetchNotifications = (enabled = true) => {
  return useInfiniteQuery(
    [QueryKeys.NOTIFICATION_LIST, useActiveOrgId()],
    ({ pageParam }) => fetchNotifications(pageParam),
    {
      enabled,
      select: (data: any) =>
        data?.pages
          ? data.pages.flatMap((x: any) => {
              return x.data.notifications.map((notification: any) =>
                dtoToNotification(notification)
              )
            })
          : [],
      getNextPageParam: (lastPage) => {
        const startKey = lastPage?.data?.['start-key']
        if (startKey) {
          return startKey
        } else {
          return undefined
        }
      },
      staleTime: Constants.DURATION.SECOND * 2,
    }
  )
}

const fetchAllNotifications = async (pageParam = '') => {
  const pageSize = Constants.PAGE_SIZES.NOTIFICATIONS
  const URL = `/notifications-all?page-size=${pageSize}&start-key=${pageParam}`
  return await api.get(URL)
}

export const useFetchAllNotifications = (enabled = true) => {
  return useInfiniteQuery(
    [QueryKeys.NOTIFICATION_LIST_ALL, useActiveOrgId()],
    ({ pageParam }) => fetchAllNotifications(pageParam),
    {
      enabled,
      select: (data: any) =>
        data?.pages
          ? data.pages.flatMap((x: any) => {
              return x.data.notifications.map((notification: any) =>
                dtoToNotification(notification)
              )
            })
          : [],
      getNextPageParam: (lastPage) => {
        const startKey = lastPage?.data?.['start-key']
        if (startKey) {
          return startKey
        } else {
          return undefined
        }
      },
      staleTime: Constants.DURATION.SECOND * 2,
    }
  )
}

function dtoToNotification(response: any) {
  const metaData = response?.['notifications-metadata']?.['metadata'] || null
  const actions = response?.['notifications-metadata']?.['actions'] || null

  const userInfo: any = {}

  if (metaData) {
    const userInfoObj = metaData?.['user-info'] || null
    if (userInfoObj && typeof userInfoObj === 'object') {
      for (const key in userInfoObj) {
        if (userInfoObj.hasOwnProperty(key)) {
          userInfo[key] = dtoToProfile(userInfoObj[key])
        }
      }
    }
  }

  const notification = {} as NotificationItem
  notification.businessId = response['business-id']
  notification.title = response['title']
  notification.notificationId = response['notification-id']
  notification.notificationScenario = (response['notification-scenario'] ||
    '') as NotificationScenario
  notification.summary = response.summary
  notification.notificationMetaData = {
    metaData: {
      businessId: metaData?.['business-id'],
      userId: metaData?.['user-id'],
      userInfo: userInfo,
      threadId: metaData?.['thread-id'],
      clipId: metaData?.['clip-id'],
      taskId: metaData?.['task-id'],
      todoListId: metaData?.['todo-list-id'],
    },
    actions: actions,
  }

  notification.readStatus = response['read-status'] as NotificationReadStatus
  notification.gtCreatedTime = response['gt-created-time']

  return notification
}

//-----------Get Badge Count--------------
const fetchBadgeCount = async () => {
  return await api.get('/notifications/badges')
}

export const useFetchBadgeCount = () => {
  return useQuery(
    [QueryKeys.BADGE_COUNTS, useActiveOrgId()],
    () => fetchBadgeCount(),
    {
      select: (data) => {
        const respBadges = data?.data?.badges
        if (respBadges) {
          return {
            cumulative_count: respBadges?.['cumulative-count'] || 0,
            details: Object.values(respBadges?.details || {}).reduce(
              (obj: any, item: any) =>
                Object.assign(obj, { [item['business-id']]: item.count }),
              {}
            ),
          }
        } else {
          return {}
        }
      },
    }
  )
}
