import { AppColors } from '@gotradie/gt-components'
import { TaskBuilder, TodoListBuilder } from '../prototypes/TodoList'
import {
  Location,
  TaskCentralTab,
  TaskCentralTabKeys,
  TaskGalleryTab,
  TaskGalleryTabKeys,
  TaskGalleryTabScreenProps,
  TaskStatus,
  TaskStatusObject,
  TodoCentralTab,
  TodoCentralTabKeys,
  User,
} from '../types/TodoList'
import Constants from './Constants'
import DateTimeUtils from './DateTimeUtils'
import moment from 'moment'
import QueryKeys from './QueryKeys'
import { QueryClient } from 'react-query'
import { Task } from 'react-native'

export function getTodoListTitle(todoList: TodoListBuilder) {
  if (!todoList) {
    return ''
  } else if (todoList.title !== '' && todoList.title !== undefined) {
    return todoList.title
  } else if (todoList.enableTodoDateTime && todoList.todoDate) {
    return `${DateTimeUtils.formatDateTime(todoList.todoDate)} todo List`
  } else if (!!todoList.createdTime) {
    return `${DateTimeUtils.formatDateTime(todoList.createdTime)} todo List`
  }

  return ''
}

export function checkTaskIsOverdue(
  taskItem: TaskBuilder,
  format = 'YYYY-MM-DD h:mm a'
) {
  if (
    taskItem.taskId !== undefined &&
    taskItem.enablePeriod === true &&
    taskItem.taskStatus !== TaskStatus.completed
  ) {
    return (
      DateTimeUtils.parseDateTimeToMoment(
        taskItem.period?.endDate,
        format
      ) as moment.Moment
    ).isBefore(moment())
  }
  return false
}

export function filterTasksByTodoList(tasks: TaskBuilder[]) {
  const groups = tasks.reduce((groups: any, task: TaskBuilder) => {
    const todoId: string = task.todoListId ?? ''
    if (!groups[todoId]) {
      groups[todoId] = []
    }
    groups[todoId].push(task)
    return groups
  }, {})

  return Object.keys(groups).map((todoId) => {
    return {
      id: todoId,
      title: todoId,
      data: groups[todoId],
    }
  })
}

export function getAddressToDisplay(address: Location, suburbOnly = false) {
  if (address) {
    const location: string = suburbOnly
      ? address?.suburb || ''
      : address?.name || ''
    return location
      .trim()
      .replace(/^[\s|]+|[\s|]+$/g, '')
      .replace(/\s*\|\s*/g, ', ')
  }
  return ''
}

export function removePlaceHoldersInTitle(str: string, names: string[]) {
  const regex = new RegExp(`@(${names.join('|')})(?=\\s|$)`, 'g')
  return str.replace(regex, '').trim()
}

type PrepareMessageProps = {
  messageId: string
  threadId: string
  currentUserId: string
  effectiveUserId: string
  todoListId: string
  sequenceNumber: string
}

export const prepareMessage = (payload: PrepareMessageProps) => {
  const {
    messageId,
    threadId,
    currentUserId,
    effectiveUserId,
    todoListId,
    sequenceNumber,
  } = payload

  return {
    'message-id': messageId,
    'user-id': currentUserId,
    'posted-by': effectiveUserId,
    message: '',
    'thread-id': threadId,
    metadata: {
      media: [
        {
          'todolist-id': todoListId,
          type: Constants.MESSAGE_MEDIA_TYPE.TODO_LIST,
        },
      ],
    },
    sequence: sequenceNumber,
    'show-info': { [effectiveUserId]: true },
  }
}

export const prepareQueryKey = (threadId: string, activeOrgId: string) => {
  return [QueryKeys.MESSAGES, threadId, activeOrgId]
}

export const getTodoCentralTabs = (): TodoCentralTab[] => {
  return [
    {
      tabKey: TodoCentralTabKeys.YourTodos,
      title: `Your todo's`,
      icon: { name: 'List' },
      indicator: false,
      activeTextColor: AppColors.common.white,
      inactiveTextColor: AppColors.common.subtitleText,
    },
    {
      tabKey: TodoCentralTabKeys.Drafts,
      title: 'Drafts',
      icon: { name: 'ListEdit' },
      indicator: false,
      activeTextColor: AppColors.common.white,
      inactiveTextColor: AppColors.common.subtitleText,
    },
  ]
}

export const getTaskCentralTabs = (): TaskCentralTab[] => {
  return [
    {
      tabKey: TaskCentralTabKeys.Outstanding,
      title: `Outstanding`,
      icon: { name: 'List' },
      indicator: false,
      activeTextColor: AppColors.common.white,
      inactiveTextColor: AppColors.common.subtitleText,
    },
    {
      tabKey: TaskCentralTabKeys.Completed,
      title: 'Completed',
      icon: { name: 'Valid' },
      indicator: false,
      activeTextColor: AppColors.common.white,
      inactiveTextColor: AppColors.common.subtitleText,
    },
  ]
}

export const getTodoTaskDetailsMediaTabs = (): TaskGalleryTabScreenProps[] => {
  return [
    {
      tabKey: TaskGalleryTabKeys.Media,
      title: `Media`,
      icon: { name: 'Picture' },
      indicator: false,
      activeTextColor: AppColors.common.white,
      inactiveTextColor: AppColors.common.subtitleText,
    },
    {
      tabKey: TaskGalleryTabKeys.Docs,
      title: `Docs`,
      icon: { name: 'Copy' },
      indicator: false,
      activeTextColor: AppColors.common.white,
      inactiveTextColor: AppColors.common.subtitleText,
    },
    {
      tabKey: TaskGalleryTabKeys.Clips,
      title: `Clips`,
      icon: { name: 'Clip' },
      indicator: false,
      activeTextColor: AppColors.common.white,
      inactiveTextColor: AppColors.common.subtitleText,
    },
  ]
}

export const taskListFilterActions = {
  [Constants.TASK_FILTERS.TASK_STATUS.key]: (
    data: TaskBuilder[],
    taskStatus: TaskStatusObject
  ) =>
    data.filter((item) => (item.taskStatus as TaskStatus) === taskStatus.key),
  [Constants.TASK_FILTERS.TASK_ASSIGNEE.key]: (
    data: TaskBuilder[],
    assignee: User
  ) =>
    data.filter(
      (item) => `${assignee.effectiveUserId}` in (item.taggedUsers || {})
    ),
}
export const deleteTaskMediaCache = (
  queryClient: QueryClient,
  orgId: string,
  source: TaskGalleryTabKeys,
  taskId: string,
  mediaFiles: { 'object-id': string }[]
) => {
  if (queryClient) {
    const tab = source

    const queryKey = [QueryKeys.TODO_TASK_GALLERY_ITEMS, taskId, tab, orgId]
    const currentData: any = queryClient.getQueryData(queryKey)

    const currentPages = currentData?.pages || []

    for (let i = 0; i < currentPages.length; i++) {
      const page = currentPages[i]
      const data = page.data || {}

      let currentFiles = [] // current files the the page

      if (tab === Constants.GALLERY_MEDIA_CATEGORY.MEDIA) {
        currentFiles = data.media || []
      } else if (tab === Constants.GALLERY_MEDIA_CATEGORY.DOCS) {
        currentFiles = data.docs || []
      } else {
        // clips
        currentFiles = data.clips || []
      }

      if (currentFiles.length > 0) {
        for (let i = 0; i < mediaFiles.length; i++) {
          // loops through items
          const mediaFile = mediaFiles[i]
          const index = currentFiles.findIndex(
            (item: any) => item['object-id'] === mediaFile['object-id']
          )
          if (index > -1) {
            // found existing item
            delete currentFiles[index]
            break
          }
        }
      }

      currentPages[i] = {
        ...page,
        data: {
          ...data,
          [tab]: currentFiles,
        },
      }
    }

    queryClient.setQueryData(queryKey, (oldQueryData: any) => {
      if (oldQueryData) {
        return {
          ...oldQueryData,
          pages: currentPages,
        }
      }
      return oldQueryData
    })
  }
}
