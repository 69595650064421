import { View, StyleSheet, TouchableOpacity, Image } from 'react-native'
import {
  Text,
  ComponentOptions,
  AppColors,
  Spacer,
  Icon,
} from '@gotradie/gt-components'
import { Util } from '../../common'

const styles = StyleSheet.create({
  textWrapperStyle: {
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
  },
  downloadBtnApple: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: ComponentOptions.SPACES.MEDIUM,
    backgroundColor: AppColors.common.tertiary,
    borderRadius: 8,
    marginBottom: ComponentOptions.SPACES.SMALL,
  },
  downloadBtnWindows: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: ComponentOptions.SPACES.MEDIUM,
    backgroundColor: AppColors.common.tertiary,
    borderRadius: 8,
  },
  downloadBtnInnerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginEnd: ComponentOptions.SPACES.SMALL,
  },
  downloadBtnIconStyle: {
    width: 32,
    height: 32,
    marginRight: ComponentOptions.SPACES.MEDIUM,
  },
})

const installPWASection = () => {
  const installPWAApplication = () => {
    const pwaDownload = document.getElementById('pwa-download')
    pwaDownload?.click()
  }

  return (
    <View>
      <Spacer size={ComponentOptions.SPACES.MEDIUM} />
      <View style={styles.textWrapperStyle}>
        <Text
          color={AppColors.common.white}
          body
        >{`Get GoTradie on your Desktop`}</Text>
      </View>
      <Spacer size={ComponentOptions.SPACES.MEDIUM} />

      {Util.detectPlatform() === 'mac' ? (
        <TouchableOpacity
          style={styles.downloadBtnApple}
          onPress={() => installPWAApplication()}
        >
          <View style={styles.downloadBtnInnerContainer}>
            <Image
              source={require(`../../images/brands-logo-apple.png`)}
              style={styles.downloadBtnIconStyle}
              resizeMode={'contain'}
            />
            <Text color={AppColors.common.white} body>{`Download on Mac`}</Text>
          </View>
          <Icon name={'Download'} size={20} fill={AppColors.common.primary} />
        </TouchableOpacity>
      ) : null}

      {Util.detectPlatform() === 'windows' ? (
        <TouchableOpacity
          style={styles.downloadBtnWindows}
          onPress={() => installPWAApplication()}
        >
          <View style={styles.downloadBtnInnerContainer}>
            <Image
              source={require('../../images/brands-logo-windows.png')}
              style={styles.downloadBtnIconStyle}
              resizeMode={'contain'}
            />
            <Text
              color={AppColors.common.white}
              body
            >{`Download on Windows`}</Text>
          </View>
          <Icon name={'Download'} size={20} fill={AppColors.common.primary} />
        </TouchableOpacity>
      ) : null}

      {Util.detectPlatform() === 'other' ? (
        <TouchableOpacity
          style={styles.downloadBtnWindows}
          onPress={() => installPWAApplication()}
        >
          <View style={styles.downloadBtnInnerContainer}>
            <Text color={AppColors.common.white} body>{`Download`}</Text>
          </View>
          <Icon name={'Download'} size={20} fill={AppColors.common.primary} />
        </TouchableOpacity>
      ) : null}
    </View>
  )
}
export default installPWASection
