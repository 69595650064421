import { useEffect, useState } from 'react'
import { Util } from '../../common'
import { AppColors, ComponentOptions } from '@gotradie/gt-components'
import { View, StyleSheet } from 'react-native'

const styles = StyleSheet.create({
  videoWrapper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    borderRadius: ComponentOptions.SPACES.SMALL,
    backgroundColor: AppColors.common.tertiary,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

type LocalStreamVideoProps = {
  elementId: string
  mediaStream: MediaStream
  hidePreview?: boolean
  sessionId?: string
  userId?: string
  placeholder?: string
  wrapperCustomStyles?: any
  videoScale?: string
}

const LocalStreamVideo = ({
  mediaStream,
  elementId,
  hidePreview: hide,
  placeholder,
  wrapperCustomStyles,
  videoScale,
}: LocalStreamVideoProps) => {
  const [videoElement, setVideoElement] = useState<HTMLVideoElement | null>(
    null
  )

  useEffect(() => {
    if (!mediaStream || !videoElement) return
    try {
      videoElement.srcObject = mediaStream
    } catch (e) {
      Util.showErrorMessage('Failed to play video')
    }
  }, [videoElement, mediaStream])

  return (
    <View style={[styles.videoWrapper, wrapperCustomStyles]}>
      {!hide && (
        <video
          playsInline
          muted
          autoPlay
          disablePictureInPicture
          id={elementId}
          style={{
            width: 'auto',
            height: '100%',
            transform: videoScale || 'scale(-1,1)',
          }}
          placeholder={placeholder}
          ref={(video) => {
            setVideoElement(video)
          }}
        />
      )}
    </View>
  )
}

export default LocalStreamVideo
