import React, { useEffect, useRef, useState } from 'react'
import {
  FlatList,
  StyleSheet,
  View,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native'
import {
  AppColors,
  ComponentOptions,
  Icon,
  Text,
  Input,
  Button,
  Lozenger,
  Toggle,
  Navigation,
  Spacer,
} from '@gotradie/gt-components'
import Avatar from '../Avatar'
import { useFetchMyProfile } from '../../hooks/user/useFetchUser'
import { Constants, MessageUtils, Util } from '../../common'
import {
  Clip,
  ClipOriginType,
  CoverImageMedia,
  GalleryItem,
  GalleryTab,
  GalleyType,
  SelectedSlideGalleryItem,
  Slide,
  SlideType,
  UploadCategory,
  UploadItem,
  UploadStatus,
} from '../../types/Files'
import { Address } from '../../types/Business'
import { useActiveOrgId, useActiveUserId } from '../../context/UserContext'
import { useFetchBusiness } from '../../hooks/business/useFetchBusiness'
import { uploadObject, dataURItoFile } from '../../common/S3Util'
import CoverImageSelection from './CoverImageSelection'
import LocationSearch from './LocationSearch'
import { Thread } from '../../types/Thread'
import { useSaveClips, useUpdateClip } from '../../hooks/clip/useMutateClip'
import { useSendMessageToThread } from '../../hooks/message/useMutateMessage'
import { APIClipSlide, SlideGalleryItem } from '../../types/ClipCentral'
import { Location, ServerError } from '../../types/Common'
import ClipGalleryMediaItem from '../clipCentral/ClipGalleryMediaItem'
import * as Sentry from '@sentry/react'
import ScrollButtons from '../home/widgets/Addon/ScrollButtons'
import { segmentIdentifyUser, segmentLogEvent } from '../../segment'
import ClipRightPanelSlides from '../../components/clipCentral/clipRightPanelSlides/ClipRightPanelSlides'
import { useFetchClip } from '../../hooks/clip/useFetchClip'
import GalleryPreviewSidebar from '../clipCreation/GalleryPreviewSidebar'
import ThreadList from '../galleyShareTab/ThreadList'
import { TaskBuilder } from '../../prototypes/TodoList'
import { useAddMediaToTaskGallery } from '../../hooks/todolist/useMutateTodoList'
import {
  TaskGalleryMediaCategory,
  TaskGalleryTab,
  TaskGalleryTabKeys,
} from '../../types/TodoList'

const styles = StyleSheet.create({
  mainWrapperStyle: {
    flex: 1,
    backgroundColor: AppColors.common.white,
    borderTopStartRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
    borderTopEndRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
    paddingHorizontal: ComponentOptions.SPACES.MEDIUM,
    paddingTop: ComponentOptions.SPACES.MEDIUM,
  },
  basicInfoWrapperStyle: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  firstSectionWrapperStyle: {
    flexDirection: 'column',
    flex: 1,
  },
  sectionWrapperStyle: {
    flexDirection: 'column',
    paddingTop: ComponentOptions.SPACES.MEDIUM,
  },
  inputWrapperStyle: {
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: AppColors.common.subtitleText,
    borderBottomStartRadius: 0,
    borderBottomEndRadius: 0,
  },
  inputStyle: {
    borderWidth: 0,
  },
  sectionSubWrapperDescriptionStyle: {
    flexDirection: 'row',
    paddingTop: ComponentOptions.SPACES.SMALL,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  buttonSectionWrapperStyle: {
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingBottom: ComponentOptions.SPACES.MEDIUM,
  },
  mediaUploadStatusWrapperStyle: {
    flexDirection: 'row',
    paddingVertical: ComponentOptions.SPACES.SMALL,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  addToHighlightsSwitchContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  avatarContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: ComponentOptions.SPACES.MEDIUM,
  },
  flatListContainerStyle: { flexWrap: 'wrap', maxWidth: 343 },
  clipMediaItemStyle: {
    borderRadius: ComponentOptions.BORDER_RADIUS.DEFAULT,
    overflow: 'hidden',
  },
  clipMediaContainerStyle: {
    height: 172,
    width: 122,
    overflow: 'hidden',
    paddingTop: ComponentOptions.SPACES.MEDIUM,
    paddingBottom: 0,
    paddingLeft: 0,
  },
  navigationWrapper: {
    backgroundColor: AppColors.gradient.color1,
  },
  slidesWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  slidesViewAllWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  slidesViewAllBtn: {
    marginRight: ComponentOptions.SPACES.SMALL,
  },
  slidesGrid: {
    flex: 1,
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
  },
  slideDetailed: {
    flex: 1,
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
  },
})

type SlidesListProps = {
  uploadedSlides: APIClipSlide[]
  setViewAllSlidesGridVisibility: (value: boolean) => void
  setIsEditCoverModalVisible: (value: boolean) => void
}
const SlidesList = (props: SlidesListProps) => {
  const {
    uploadedSlides,
    setViewAllSlidesGridVisibility,
    setIsEditCoverModalVisible,
  } = props

  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const hasNextPage = uploadedSlides
    ? currentIndex + 1 < uploadedSlides?.length
    : false
  const hasPrevPage = currentIndex > 0

  const ref = useRef<any>()

  useEffect(() => {
    if (uploadedSlides?.length > 0) {
      onScroll()
    }
  }, [currentIndex])

  function onScroll() {
    ref?.current?.scrollToIndex({
      animated: true,
      index: currentIndex,
    })
  }

  function onPressNext() {
    setCurrentIndex(() =>
      uploadedSlides && currentIndex > uploadedSlides?.length - 3
        ? uploadedSlides?.length - 1
        : currentIndex + 2
    )
  }

  function onPressPrevious() {
    setCurrentIndex(() =>
      uploadedSlides && currentIndex > 2 ? currentIndex - 2 : 0
    )
  }

  function setListIndex(
    listOffsetWidth: number,
    wrapperWidth: number,
    listWidth: number
  ) {
    if (uploadedSlides && listOffsetWidth == 0) {
      setCurrentIndex(0)
    }
    if (uploadedSlides && listOffsetWidth + wrapperWidth == listWidth) {
      setCurrentIndex(uploadedSlides.length - 1)
    }
  }

  return (
    <React.Fragment>
      <Spacer />
      <View style={styles.slidesWrapper}>
        <Text body_small color={AppColors.common.subtitleText}>{`Slides`}</Text>

        {/* Implement later */}
        {/* <MessageNote
            onPress={() => {}}
            color={AppColors.common.subtitleText}
            arMessages={[{ title: 'View all' }]}
            paddingHorizontal={0}
            styleProps={{ paddingTop: 0 }}
        /> */}
        <View style={styles.slidesViewAllWrapper}>
          <TouchableOpacity
            style={styles.slidesViewAllBtn}
            onPress={() => {
              setViewAllSlidesGridVisibility(true)
              setIsEditCoverModalVisible(false)
            }}
          >
            <Text body_small color={AppColors.common.grey}>
              {'View all'}
            </Text>
          </TouchableOpacity>
          <ScrollButtons
            hasNextPage={hasNextPage}
            hasPrevPage={hasPrevPage}
            onPressNext={onPressNext}
            onPressPrev={onPressPrevious}
            enableButtonColor={AppColors.common.subtitleText}
            disableButtonColor={AppColors.common.concrete}
          />
        </View>
      </View>

      <FlatList
        data={uploadedSlides}
        extraData={uploadedSlides}
        ref={ref}
        initialScrollIndex={0}
        onScroll={(e) => {
          setListIndex(
            e.nativeEvent.contentOffset.x,
            e.nativeEvent.layoutMeasurement.width,
            e.nativeEvent.contentSize.width
          )
        }}
        keyExtractor={(item, index) => `section${item['slide-id']}${index}`}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        renderItem={({ item, index }) => (
          <ClipGalleryMediaItem
            key={`ClipGalleryMediaItem${index}${item['slide-id']}`}
            index={index}
            columns={1}
            item={
              {
                slideType: item['slide-type'],
                thumbnailKey: item['thumbnail-key'],
                slideKey: item['slide-key'],
                uploadStatus: UploadStatus.uploaded,
                uploadingStatusValue: 0,
                tab: GalleryTab.media,
                galleryType:
                  item['slide-type'] === SlideType.photo
                    ? GalleyType.photo
                    : GalleyType.video,
                uploadCategory:
                  item['slide-type'] === SlideType.photo
                    ? UploadCategory.photo
                    : UploadCategory.video,
              } as SlideGalleryItem
            }
            onPressItem={() => {}}
            containerStyle={styles.clipMediaContainerStyle}
            innerItemStyle={styles.clipMediaItemStyle}
            padding={ComponentOptions.SPACES.SMALL}
          />
        )}
      />
    </React.Fragment>
  )
}

const MemorizedSlideList = React.memo(SlidesList)

type ClipSubmissionProps = {
  selectedMedia: UploadItem[]
  thread?: Thread
  task?: TaskBuilder | null
  onClose: () => void
  clipOriginType: ClipOriginType
  isEmptyClipCreation?: boolean
  isEditMode?: boolean
  clipObj?: Clip
  showNavigation?: boolean
  revertClipCreation?: (revert: boolean) => void
}

function ClipSubmission({
  selectedMedia,
  thread,
  task,
  onClose,
  clipOriginType,
  isEmptyClipCreation,
  isEditMode,
  clipObj: clip,
  showNavigation,
  revertClipCreation,
}: ClipSubmissionProps) {
  const activeOrgId = useActiveOrgId()
  const userId = useActiveUserId()

  const { data: profile } = useFetchMyProfile()

  const { data: business, isLoading: isFetchingBusiness } =
    useFetchBusiness(activeOrgId)

  const { data: clipObj } = useFetchClip(clip?.clipId || '')

  const selectedLocation = getLocation(clipObj?.location)

  const [coverImage, setCoverImage] = useState<CoverImageMedia | null>(
    clipObj?.coverImage
      ? {
          imageSource: 'gallery',
          objectId: clipObj?.coverImage,
          galleryType: GalleyType.photo,
          uploadStatus: UploadStatus.uploaded,
        }
      : null
  )
  const [isEditCoverModalVisible, setIsEditCoverModalVisible] = useState(false)
  const [isLocationModalVisible, setIsLocationModalVisible] = useState(false)
  const [locationSuggestions, setLocationSuggestions] = useState<Address[]>(
    selectedLocation ? [selectedLocation] : []
  )
  const [location, setLocation] = useState<Address | null>(selectedLocation)
  const [title, setTitle] = useState(clipObj?.clipTitle || '')
  const [description, setDescription] = useState(clipObj?.clipDescription || '')
  const [isSaving, setSaving] = useState(false)
  const [isSending, setSending] = useState(false)
  const [isAddingToTask, setAddingToTask] = useState(false)
  const [addToHighLights, setAddToHighLights] = useState(true)
  const [clipId, setClipId] = useState(clipObj?.clipId || '')
  const [uploadedSlidesQueue, setUploadedSlidesQueue] = useState<
    APIClipSlide[]
  >(clipObj?.slides ? convertSlides(clipObj?.slides) : [])
  const [isViewAllSlidesGridVisible, setViewAllSlidesGridVisibility] =
    useState(false)
  const [selectedSlide, setSelectedSlide] = useState<SlideGalleryItem | null>(
    null
  )
  const [isThreadList, setIsThreadList] = useState<boolean>(false)
  const [enableGallerySelection, setEnableGallerySelection] =
    useState<boolean>(false)

  const [selectedItems, setSelectedItems] = useState<
    SelectedSlideGalleryItem[]
  >([])

  useEffect(() => {
    setUploadedSlidesQueue(clipObj?.slides || [])
  }, [clipObj])

  const { mutate: saveClip, isLoading: isSavingClips } = useSaveClips(
    onSuccessSaveClips,
    onErrorSaveClips
  )

  const { mutate: updateClip, isLoading: isUpdatingClips } = useUpdateClip(
    onSuccessUpdateClips,
    onErrorUpdateClips
  )

  const { mutate: sendMessageToThreadApi } = useSendMessageToThread(
    () => onSuccessSend(),
    onErrorSend
  )

  const { mutate: addToTaskGallery } = useAddMediaToTaskGallery(
    onSuccessAddToTaskGallery,
    onErrorAddToTaskGallery
  )

  const { data: currentUser } = useFetchMyProfile()

  const uploadedItemsClip = (clipObj?.slides || []).map(
    (value: any) =>
      ({
        galleryType:
          value.slideType === SlideType.photo
            ? GalleyType.photo
            : GalleyType.video,
        uploadStatus: Constants.FILE_UPLOAD_STATUS.UPLOADED,
        objectId: value.slideKey,
      } as UploadItem)
  )

  function getLocation(loc: Location | undefined): Address | null {
    if (loc) {
      const address = {
        postalCode: loc.postalCode,
        address: loc.address,
        route: loc.route,
        cordinates: loc.coordinates,
        streetNumber: loc.streetNumber,
        streetAddress: loc.streetAddress,
        suburb: loc.suburb,
        state: loc.state,
        subpremise: loc.subPremise,
        name: loc.name,
        description: loc.name,
      }
      return address
    }
    return null
  }

  function onSuccessSaveClips(data: any, variables: any) {
    const segmentUserDetails = {
      user_id: currentUser?.userId,
      phone_number: currentUser?.phoneNumber,
      first_name: currentUser?.firstName,
      last_name: currentUser?.lastName,
      email: currentUser?.email,
      profile_type: currentUser?.profileType,
    }
    segmentIdentifyUser(segmentUserDetails.user_id, segmentUserDetails)
    segmentLogEvent('create_clip', {
      clip_id: variables['clip-id'],
      empty_clip: variables['empty-clip'],
      ...segmentUserDetails,
    })

    setSaving(false)

    if (task) {
      sendClipToTaskGallery()
    } else if (thread) {
      sendClipToThread()
    } else {
      Util.showSuccessMessage('Clip saved successfully')
      onClose()
    }
  }

  function onSuccessUpdateClips(data: any, variables: any) {
    const clipId = variables['clip-id']
    const segmentUserDetails = {
      user_id: currentUser?.userId,
      phone_number: currentUser?.phoneNumber,
      first_name: currentUser?.firstName,
      last_name: currentUser?.lastName,
      email: currentUser?.email,
      profile_type: currentUser?.profileType,
    }
    segmentIdentifyUser(segmentUserDetails.user_id, segmentUserDetails)
    segmentLogEvent('update_clip', {
      clip_id: clipId,
      ...segmentUserDetails,
    })
    setSaving(false)
    Util.showSuccessMessage('Clip updated successfully')
    onClose()
  }

  function onErrorSaveClips() {
    Util.showErrorMessage('Failed to share clips. Please try again.')
    setSaving(false)
  }

  function onErrorUpdateClips() {
    Util.showErrorMessage('Failed to update clip. Please try again.')
    setSaving(false)
  }

  function onSuccessSend() {
    Util.showSuccessMessage('Successfully shared clip to thread')
    setSending(false)
    onClose()
    revertClipCreation?.(false)
  }

  function onSuccessAddToTaskGallery() {
    Util.showSuccessMessage('Successfully added clip to task gallery')
    setAddingToTask(false)
    onClose()
    revertClipCreation?.(false)
  }

  function onErrorSend() {
    Util.showErrorMessage('Failed to share clips. Please try again.')
    setAddingToTask(false)
  }

  function onErrorAddToTaskGallery(er: ServerError) {
    Util.showErrorMessage(`Failed to add clip to task gallery.`)
    setSending(false)
  }

  function sendClipToThread() {
    if (thread) {
      setSending(true)
      const messageObj = {
        'posted-by': MessageUtils.getEffectiveUserId(thread, profile?.userId),
        'message-id': `${Util.generateUUID()}`,
        message: '',
        metadata: {
          media: [
            {
              type: Constants.MESSAGE_MEDIA_TYPE.CLIP,
              'clip-id': clipId,
            },
          ],
        },
      }
      sendMessageToThreadApi({
        threadId: thread.threadId,
        message: messageObj,
        threadFilter: MessageUtils.getThreadFilterFromType(thread.threadType),
        isClipCreation: true,
      })
    }
  }

  function sendClipToTaskGallery() {
    if (task) {
      setAddingToTask(true)
      addToTaskGallery({
        source: TaskGalleryTab.clips,
        todoListId: task.todoListId as string,
        taskId: task.taskId as string,
        mediaModel: [
          {
            key: clipId,
            type: TaskGalleryMediaCategory.CLIPS,
            'todo-list-id': task.todoListId as string,
            'clip-id': clipId,
          },
        ],
        tab: TaskGalleryTabKeys.Clips,
      })
    }
  }

  const getRemainCharacters = (text: string, limit: number) => {
    return limit - text.length
  }

  useEffect(() => {
    setIsEditCoverModalVisible(false)
    setIsLocationModalVisible(false)
    generateCoverImages()
    if (selectedMedia.length > 0) {
      setClipId(Util.generateUUID())
      onUploadSlides()
    }
  }, [selectedMedia])

  function convertSlides(slides: Slide[]): APIClipSlide[] {
    return slides.map(
      (slide) =>
        ({
          'slide-type': slide.slideType,
          'slide-key': slide.slideKey,
          'thumbnail-key': slide.thumbnailKey,
          'slide-sequence': slide.slideSequence,
          'slide-id': slide.slideId,
          'clip-id': clipId,
          'uploaded-by-user-business-id': activeOrgId,
          'uploaded-by-user-id': userId,
        } as APIClipSlide)
    )
  }

  async function onUploadSlides() {
    if (
      selectedMedia.length !== uploadedSlidesQueue.length &&
      selectedMedia.length > 0
    ) {
      const unploadingSlides: UploadItem[] = []
      const unploadedSlides: UploadItem[] = []
      const uploadedSlidesFromDevice: APIClipSlide[] = []
      const uploadedSlidesFromGallery: APIClipSlide[] = []

      selectedMedia.map((item) => {
        if (item.uploadStatus === Constants.FILE_UPLOAD_STATUS.UPLOADING) {
          unploadingSlides.push(item)
          return true
        } else {
          unploadedSlides.push(item)
          return true
        }
      })

      try {
        if (unploadingSlides.length > 0) {
          for (const slide of unploadingSlides) {
            if (!slide.file) {
              return
            }
            const isVideo = slide.galleryType === GalleyType.video
            if (isVideo) {
              const thumbKey = Util.getThumbnailKeyFromVideoObjectKey(
                slide.objectId
              )
              const thumbFilename = Util.generateUUID()
              const thumbFile = dataURItoFile(
                thumbFilename,
                slide.thumbUrl || ''
              )
              const thumbUploaded = await uploadItem(thumbKey ?? '', thumbFile)
              if (!thumbUploaded) {
                return
              }
            }
            const mediaUploaded = await uploadItem(slide.objectId, slide.file)
            if (!mediaUploaded) {
              return
            }
            const clipData = getClipData(
              slide,
              isVideo,
              unploadingSlides.indexOf(slide)
            )

            uploadedSlidesFromDevice.push(clipData)
          }
        }
        if (unploadedSlides.length > 0) {
          const promises = unploadedSlides.map((slide, i) => {
            const isVideo = slide.galleryType === GalleyType.video
            const clipData = getClipData(
              slide,
              isVideo,
              i + uploadedSlidesFromDevice.length
            )
            uploadedSlidesFromGallery.push(clipData)
            return true
          })

          await Promise.all(promises)
        }

        setUploadedSlidesQueue([
          ...uploadedSlidesFromDevice,
          ...uploadedSlidesFromGallery,
        ])
      } catch (error) {
        Sentry.captureException(error, {
          tags: { clip_submission: 'upload_slides' },
          level: 'error',
        })
        setUploadedSlidesQueue([])
      }
    }
  }

  const uploadItem = async (key: string, file: File) => {
    try {
      const metadata = getCommonMetadata()
      const uploaded = await uploadObject(
        key,
        file,
        {
          'media-source': 'gallery',
          'media-category': Constants.MEDIA_CATEGORY_TYPES.CLIP,
          origin: thread
            ? Constants.MEDIA_ORIGIN_TYPES.THREAD
            : Constants.MEDIA_ORIGIN_TYPES.BUSINESS_PROFILE,
          'origin-id': thread?.threadId || activeOrgId,
          'file-name': file.name,
          extension: file.type,
          ...metadata,
        },
        process.env.MEDIA_S3_BUCKET
      )
      if (!uploaded) {
        return false
      }
      return true
    } catch (error: any) {
      Sentry.captureException(error, {
        tags: { clip_submission: 'upload_item' },
        level: 'error',
      })
      Util.showErrorMessage(error?.message || 'Error uploading slides')
      return false
    }
  }

  function getCommonMetadata() {
    return {
      'uploaded-by-user-id': profile?.userId,
      'business-id': business?.businessId,
      ...(thread && {
        'uploaded-by-effective-user-id': MessageUtils.getEffectiveUserId(
          thread,
          profile?.userId
        ),
      }),
      ...(thread && { 'thread-id': thread.threadId }),
    }
  }

  function getClipData(item: UploadItem, isVideo: boolean, index: number) {
    return {
      'slide-type': item.galleryType,
      'slide-key': item.objectId,
      'thumbnail-key': isVideo
        ? Util.getThumbnailKeyFromVideoObjectKey(item.objectId)
        : item.objectId,
      'slide-sequence': index,
      'slide-id': Util.generateUUID(),
      'clip-id': clipId,
      'uploaded-by-user-business-id': activeOrgId,
      'uploaded-by-user-id': profile?.userId,
    } as APIClipSlide
  }

  async function uploadCoverImage() {
    if (coverImage?.uploadStatus === UploadStatus.uploaded) {
      return coverImage.objectId as string
    } else {
      if (!coverImage?.file) {
        throw new Error('Cover image path is empty')
      }
      const coverImageKey = `images/${Util.generateUUID()}`
      const metadata = getCommonMetadata()

      const uploaded = await uploadObject(
        coverImageKey,
        coverImage.file,
        {
          'media-source': 'gallery',
          'media-category': Constants.MEDIA_CATEGORY_TYPES.MEDIA,
          origin: thread
            ? Constants.MEDIA_ORIGIN_TYPES.THREAD
            : Constants.MEDIA_ORIGIN_TYPES.BUSINESS_PROFILE,
          'origin-id': thread?.threadId || activeOrgId,
          'file-name': '',
          extension: coverImage.mimeType,
          ...metadata,
        },
        process.env.MEDIA_S3_BUCKET
      )
      if (uploaded) {
        return coverImageKey
      } else {
        throw new Error('Unable to upload the cover image')
      }
    }
  }

  function onSubmitClips() {
    if (isEmptyClipCreation) {
      saveClipObj(null)
    } else {
      if (coverImage === null) {
        Util.showErrorMessage('Cover image need to be added')
        return
      }
      setSaving(true)
      uploadCoverImage()
        .then((coverImageKey) => {
          saveClipObj(coverImageKey)
        })
        .catch((e) => {
          Util.showErrorMessage(e)
          setSaving(false)
        })
    }
  }

  function onUpdateClips() {
    if (isEmptyClipCreation) {
      updateClipObj(null)
    } else {
      if (coverImage === null) {
        Util.showErrorMessage('Cover image need to be added')
        return
      }

      if (coverImage.objectId === undefined && coverImage.file) {
        setSaving(true)
        uploadCoverImage()
          .then((coverImageKey) => {
            updateClipObj(coverImageKey)
          })
          .catch((e) => {
            Util.showErrorMessage(e)
            setSaving(false)
          })
      } else {
        updateClipObj(coverImage.objectId || '')
      }
    }
  }

  function saveClipObj(coverImageKey: string | null) {
    const obj = {
      'clip-id': clipId,
      slides: isEmptyClipCreation ? undefined : uploadedSlidesQueue,
      'tagged-entities': {},
      'posted-by': profile?.userId,
      'business-id': business?.businessId,
      'business-handle': business?.handle,
      'cover-image': coverImageKey || '',
      location: location,
      'clip-title': title,
      'clip-description': description,
      'add-to-highlights': thread ? addToHighLights : true,
      'highlights-metadata': { 'business-id': business?.businessId },
      origin: clipOriginType,
      'empty-clip': isEmptyClipCreation,
      ...(thread && { 'thread-id': thread.threadId }),
    }
    saveClip(obj)
  }

  function updateClipObj(coverImageKey: string | null) {
    const obj: any = {
      'clip-id': clipId,

      'clip-title': title,
      'clip-description': description,
      'empty-clip': isEmptyClipCreation,
      'thread-id': clipObj?.threadId || undefined,
    }

    if (location) {
      obj['location'] = location
    }
    if (coverImageKey) {
      obj['cover-image'] = coverImageKey
    }
    updateClip(obj)
  }
  async function generateCoverImages() {
    if (!!selectedMedia && selectedMedia.length > 0 && coverImage === null) {
      const media = selectedMedia[0]
      const path =
        media.galleryType === GalleyType.video
          ? media.thumbUrl
          : media.uploadingUrl

      const key =
        media.uploadStatus === Constants.FILE_UPLOAD_STATUS.UPLOADED
          ? media.galleryType === GalleyType.video
            ? (Util.getThumbnailKeyFromVideoObjectKey(media.objectId) as string)
            : media.objectId
          : undefined

      const thumbFilename = Util.generateUUID()
      let thumbFile
      if (
        path &&
        media.galleryType === GalleyType.video &&
        media.uploadStatus === Constants.FILE_UPLOAD_STATUS.UPLOADING
      ) {
        thumbFile = dataURItoFile(thumbFilename, path || '')
      }

      setCoverImage({
        imageSource: 'gallery',
        mimeType: media.file?.type,
        path: path,
        filename: media.fileName,
        file: thumbFile || media.file,
        objectId: key,
        uploadStatus: media.uploadStatus,
        galleryType: media.galleryType,
      })
    }
  }

  const renderAvatarImage = () => {
    if (!isEmptyClipCreation) {
      const isCoverImageUploaded =
        coverImage?.uploadStatus === Constants.FILE_UPLOAD_STATUS.UPLOADED

      const avatarProps = {
        size: ComponentOptions.AVATAR_SIZE.SMALL,
        imageIcon:
          coverImage || isCoverImageUploaded
            ? undefined
            : {
                name: 'Camera',
                fill: AppColors.common.white,
              },
        backgroundColor: coverImage ? undefined : AppColors.common.concrete,
        onPress: isEmptyClipCreation
          ? () => {}
          : () => setIsEditCoverModalVisible(true),
      }
      return (
        <View style={styles.avatarContainer}>
          <Avatar
            imageUri={isCoverImageUploaded ? undefined : coverImage?.path}
            s3Key={isCoverImageUploaded ? coverImage?.objectId : undefined}
            avatarProps={avatarProps}
          />

          <Icon
            name={'Camera'}
            size={22}
            fill={AppColors.common.white}
            wrapperStyles={{ position: 'absolute' }}
            onPress={() => setIsEditCoverModalVisible(true)}
          />
        </View>
      )
    }

    return null
  }

  const lozengerStyles = {
    height: 35,
    marginRight: ComponentOptions.SPACES.EXTRA_SMALL,
    marginBottom: ComponentOptions.SPACES.EXTRA_SMALL,
  }

  const renderLocationItem = (item: Address) => {
    const isSelected = location && item.suburb === location.suburb
    if (isSelected) {
      return (
        <Lozenger
          onPress={() => setLocation(null)}
          textProps={{ body_small: true }}
          textTransform={ComponentOptions.TEXT_TRANSFORM_OPTIONS.NONE}
          paddingHorizontal={ComponentOptions.SPACES.MEDIUM}
          paddingVertical={ComponentOptions.SPACES.SMALL}
          styleProps={lozengerStyles}
          buttonColor={AppColors.common.subtitleText}
          type={ComponentOptions.BUTTON_TYPES.SOLID}
          message={`${item.suburb || item.name}`}
          color={AppColors.common.white}
          leftIcon={{
            name: 'Close',
            size: 10,
          }}
        />
      )
    }
    return (
      <Lozenger
        onPress={() => setLocation(item)}
        textProps={{ body_small: true }}
        textTransform={ComponentOptions.TEXT_TRANSFORM_OPTIONS.NONE}
        paddingHorizontal={ComponentOptions.SPACES.MEDIUM}
        paddingVertical={ComponentOptions.SPACES.SMALL}
        styleProps={lozengerStyles}
        buttonColor={AppColors.common.subtitleText}
        type={ComponentOptions.BUTTON_TYPES.OUTLINE}
        message={`${item.suburb || item.name}`}
      />
    )
  }

  const renderLocation = () => {
    // predefined, current suburb, current location
    return (
      <FlatList
        contentContainerStyle={styles.flatListContainerStyle}
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        data={locationSuggestions}
        extraData={locationSuggestions}
        renderItem={({ item }) => renderLocationItem(item)}
        keyExtractor={(item, index) => `location${index}`}
        horizontal={true}
        ListHeaderComponent={
          <Lozenger
            onPress={() => {
              isLoading ? () => {} : setIsLocationModalVisible(true)
            }}
            textProps={{ body_small: true }}
            textTransform={ComponentOptions.TEXT_TRANSFORM_OPTIONS.NONE}
            paddingHorizontal={ComponentOptions.SPACES.MEDIUM}
            paddingVertical={ComponentOptions.SPACES.SMALL}
            styleProps={lozengerStyles}
            buttonColor={AppColors.common.subtitleText}
            color={AppColors.common.white}
            type={ComponentOptions.BUTTON_TYPES.SOLID}
            leftIcon={{ name: 'Search' }}
            message={'Search'}
          />
        }
      />
    )
  }

  const renderAddToHighlightsSwitch = () => {
    return (
      <View style={styles.addToHighlightsSwitchContainer}>
        <View>
          <Text body color={AppColors.common.black}>
            {'Add to profile'}
          </Text>
          <Text body_small color={AppColors.common.subtitleText}>
            {`Edits made to this clip by you or other will be\nchanged on your profile`}
          </Text>
        </View>
        <Toggle
          value={addToHighLights}
          switchOffWrapperColor={AppColors.common.grey}
          onSwitch={() => setAddToHighLights((value) => !value)}
        />
      </View>
    )
  }

  function getLoadingText() {
    if (isSending) {
      return 'Saving clip to thread'
    } else if (isAddingToTask) {
      return 'Saving clip to task gallery'
    } else if (isSavingClips) {
      return 'Saving clip stories'
    } else {
      return 'Uploading media'
    }
  }

  const renderMediaUploadedStatus = () => {
    if (
      (selectedMedia.length !== uploadedSlidesQueue.length &&
        selectedMedia.length > 0) ||
      isSending
    ) {
      return (
        <View style={styles.mediaUploadStatusWrapperStyle}>
          {
            <ActivityIndicator
              style={{ marginRight: ComponentOptions.SPACES.SMALL }}
            />
          }
          <Text body_strong color={AppColors.common.grey}>
            {getLoadingText()}
          </Text>
        </View>
      )
    }
    return null
  }

  const renderCoverImageModal = () => {
    return (
      <CoverImageSelection
        coverImage={coverImage}
        selectedMedia={selectedMedia}
        onChange={(cover) => {
          setCoverImage(cover)
          setIsEditCoverModalVisible(false)
        }}
        onClose={() => setIsEditCoverModalVisible(false)}
        uploadedItems={isEditMode ? uploadedItemsClip : []}
      />
    )
  }

  const renderViewAllSlidesModal = () => {
    return (
      <View style={styles.slidesGrid}>
        <ClipRightPanelSlides
          clipId={clipId}
          onPressClose={() => setViewAllSlidesGridVisibility(false)}
          onMediaPress={(slide: SlideGalleryItem) => setSelectedSlide(slide)}
          editMode={true}
          enableGallerySelection={enableGallerySelection}
          setEnableGallerySelection={setEnableGallerySelection}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          setOpenThreadList={setIsThreadList}
        />
      </View>
    )
  }

  const renderGalleryPreviewSidebar = (clip?: Clip) => {
    return (
      clip && (
        <View style={styles.slideDetailed}>
          <GalleryPreviewSidebar
            clip={clip}
            item={selectedSlide}
            onClose={() => setSelectedSlide(null)}
            setOpenThreadList={setIsThreadList}
          />
        </View>
      )
    )
  }

  const renderThreadList = (setIsThreadList: (state: boolean) => void) => {
    return (
      clip && (
        <View style={styles.slideDetailed}>
          <ThreadList
            item={
              {
                objectId: clip.clipId,
                recordKey: clip.clipId,
                clipId: clip.clipId,
                tab: GalleryTab.clips,
                galleryType: GalleyType.clips,
                uploadedByUserId: clip.postedBy,
                gtUpdatedTime: clip.gtUpdatedTime,
                gtCreatedTime: clip.gtCreatedTime,
              } as GalleryItem
            }
            onCancel={() => setIsThreadList(false)}
          />
        </View>
      )
    )
  }

  const renderLocationModal = () => {
    return (
      <LocationSearch
        onLocationSelect={(place: Address) => {
          const notAddedBefore = locationSuggestions.some(
            (location) => location.suburb !== place.suburb
          )
          if (notAddedBefore || locationSuggestions.length === 0) {
            const locations = [...locationSuggestions, place]
            setLocationSuggestions(locations)
          }
          setIsLocationModalVisible(false)
        }}
        onClose={() => setIsLocationModalVisible(false)}
      />
    )
  }

  const isLoading =
    isSaving ||
    isSending ||
    isFetchingBusiness ||
    isSavingClips ||
    isAddingToTask

  return (
    <>
      {!isViewAllSlidesGridVisible && showNavigation ? (
        <View style={styles.navigationWrapper}>
          <Navigation
            title={isEditMode ? `Edit Clip` : `Create clip`}
            rightIcon={
              <Button
                buttonColor={AppColors.common.secondery}
                title="Close"
                type="clear"
                textProps={{ body_strong: true }}
                paddingHorizontal={0}
                onPress={onClose}
              />
            }
          />
        </View>
      ) : null}

      <View style={styles.mainWrapperStyle}>
        {!isThreadList &&
          !selectedSlide &&
          !isViewAllSlidesGridVisible &&
          !isEditCoverModalVisible &&
          !isLocationModalVisible && (
            <React.Fragment>
              <View style={styles.basicInfoWrapperStyle}>
                {renderAvatarImage()}
                <View style={styles.firstSectionWrapperStyle}>
                  <Text body_small color={AppColors.common.subtitleText}>
                    {'Clip title (optional 1)'}
                  </Text>
                  <View style={styles.inputWrapperStyle}>
                    <Input
                      paddingHorizontal={0}
                      InputWrapperStyle={styles.inputStyle}
                      placeholderTextColor={AppColors.common.subtitleText}
                      textColor={AppColors.common.tertiary}
                      placeholder={'"28 Garden Street222"'}
                      onChangeText={(txt: string) => {
                        setTitle(txt)
                      }}
                      value={title}
                      disabled={isLoading}
                    />
                  </View>
                </View>
              </View>
              <View style={styles.sectionWrapperStyle}>
                <Text body_small color={AppColors.common.subtitleText}>
                  {'Describe your clip (optional)'}
                </Text>
                <View style={styles.inputWrapperStyle}>
                  <Input
                    paddingHorizontal={0}
                    InputWrapperStyle={[styles.inputStyle, { height: 88 }]}
                    placeholderTextColor={AppColors.common.subtitleText}
                    textColor={AppColors.common.tertiary}
                    onChangeText={(txt: string) => {
                      setDescription(txt)
                    }}
                    value={description}
                    disabled={isLoading}
                    InputProps={{
                      multiline: true,
                      maxLength: Constants.MAX_CHARACTERS_INPUT.SHARE_CLIP_DES,
                    }}
                  />
                </View>
                <View style={styles.sectionSubWrapperDescriptionStyle}>
                  <Text body_small color={AppColors.common.subtitleText}>
                    {`${getRemainCharacters(
                      description,
                      Constants.MAX_CHARACTERS_INPUT.SHARE_CLIP_DES
                    )} characters left`}
                  </Text>
                </View>
              </View>
              <View style={styles.sectionWrapperStyle}>
                <Text
                  body_small
                  color={AppColors.common.subtitleText}
                  StyleProps={{ marginBottom: ComponentOptions.SPACES.MEDIUM }}
                >
                  {'Add location'}
                </Text>
                {renderLocation()}
              </View>
              {!!profile &&
                (profile.roleType === Constants.USER_ACTIVE_ROLE.tradieOwner ||
                  profile.roleType ===
                    Constants.USER_ACTIVE_ROLE.tradieAdmin) && (
                  <View style={styles.sectionWrapperStyle}>
                    <Text
                      body_small
                      color={AppColors.common.subtitleText}
                      StyleProps={{
                        marginBottom: ComponentOptions.SPACES.MEDIUM,
                      }}
                    >
                      {'Automatically share to:'}
                    </Text>
                    {renderAddToHighlightsSwitch()}
                  </View>
                )}

              <MemorizedSlideList
                uploadedSlides={uploadedSlidesQueue}
                setViewAllSlidesGridVisibility={setViewAllSlidesGridVisibility}
                setIsEditCoverModalVisible={setIsEditCoverModalVisible}
              />

              <View style={styles.buttonSectionWrapperStyle}>
                {isEditMode ? null : renderMediaUploadedStatus()}

                {isEditMode ? (
                  <Button
                    title={'Update'}
                    onPress={() => onUpdateClips()}
                    loading={isLoading || isUpdatingClips}
                  />
                ) : (
                  <Button
                    title={'SEND'}
                    disabled={
                      selectedMedia.length !== uploadedSlidesQueue.length
                    }
                    onPress={() => onSubmitClips()}
                    loading={isLoading}
                  />
                )}
              </View>
            </React.Fragment>
          )}

        {isEditCoverModalVisible && renderCoverImageModal()}
        {isLocationModalVisible && renderLocationModal()}
        {isViewAllSlidesGridVisible &&
          !selectedSlide &&
          renderViewAllSlidesModal()}
        {selectedSlide && renderGalleryPreviewSidebar(clip)}
        {isThreadList && renderThreadList(setIsThreadList)}
      </View>
    </>
  )
}

export default ClipSubmission
