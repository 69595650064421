import { Image, View } from 'react-native'
import StyleSheet from 'react-native-media-query'
import {
  AppColors,
  ComponentOptions,
  GoTradieLogo,
  Spacer,
  Text,
} from '@gotradie/gt-components'
import InstallPWASection from '../../PWA/InstallPWASection'

const { styles } = StyleSheet.create({
  placeholderWrapperStyle: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingTop: ComponentOptions.SPACES.SMALL,
    paddingBottom: ComponentOptions.SPACES.MEDIUM,
    paddingHorizontal: ComponentOptions.SPACES.LARGE * 4,
    display: 'flex',
    '@media (max-width: 1024px)': {
      paddingVertical: ComponentOptions.SPACES.LARGE * 4,
      display: 'none',
    },
    maxHeight: window.innerHeight,
  },
  imageStyle: {
    width: 545,
    height: 435,
  },
})

const DefaultContent = () => {
  return (
    <View style={styles.placeholderWrapperStyle}>
      <Image
        source={require('../../../images/home-page-graphic.png')}
        style={styles.imageStyle}
        resizeMode={'contain'}
      />
      <Spacer size={ComponentOptions.SPACES.SMALL} />
      <GoTradieLogo width={252} height={30} />
      <Spacer size={ComponentOptions.SPACES.MEDIUM} />
      <Text
        body
        color={AppColors.common.subtitleText}
      >{`Welcome to your HQ, keep track of all your messages, photos, videos, documents and more.`}</Text>
      <Spacer size={ComponentOptions.SPACES.LARGE} />
      <InstallPWASection />
    </View>
  )
}

export default DefaultContent
