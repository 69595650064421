import { useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'

import {
  Tile,
  ComponentOptions,
  Text,
  useTheme,
  AppStyles,
  AppColors,
  Navigation,
  Icon,
  Input,
} from '@gotradie/gt-components'

import StyleSheet from 'react-native-media-query'
import { Clip, GalleryItem } from '../../../types/Files'
import { TabPanel } from '../../../components/tabView'
import { TabProps } from '../../../types/Tab'
import ClipItem from '../../../components/ClipItem'
import {
  useFetchSearchClips,
  useFetchYourClips,
} from '../../../hooks/clip/useFetchClip'
import { useActiveOrgId } from '../../../context/UserContext'
import { Constants, Util } from '../../../common'
import { DeleteClipParams } from '../../../types/ClipCentral'
import DeleteBottomDrawer from '../../../components/DeleteBottomDrawer'

const { styles } = StyleSheet.create({
  titleWrapperStyle: {
    paddingRight: ComponentOptions.SPACES.MEDIUM,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  clipCreateWrapperStyle: {
    paddingLeft: ComponentOptions.SPACES.MEDIUM,
    paddingRight: ComponentOptions.SPACES.SMALL,
    paddingVertical: ComponentOptions.SPACES.MEDIUM,
  },
  inputWrapperStyle: {
    backgroundColor: AppColors.common.searchInputBackground,
    borderColor: AppColors.common.searchBorder,
    marginHorizontal: ComponentOptions.SPACES.MEDIUM,
    marginVertical: ComponentOptions.SPACES.SMALL,
    width: 'auto',
  },
  inputStyles: {
    color: AppColors.common.tertiary,
  },
  headerWrapper: {
    height: '68.5px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

type ClipCentralHeaderProps = {
  isSearching: boolean
  searchQuery: string
  isShowingSearch: boolean
  setSearchQuery: (query: string) => void
  setIsShowingSearch: (show: boolean) => void
  onBackPress?: () => void
}

const ClipCentralHeader = (props: ClipCentralHeaderProps) => {
  return (
    <>
      {!props.isShowingSearch ? (
        <View style={styles.headerWrapper}>
          <Navigation
            title={'Clip Central'}
            back={true}
            onBack={props.onBackPress}
          />
          <Icon
            name={'Search'}
            size={20}
            fill={AppColors.common.concrete}
            marginRight={ComponentOptions.SPACES.LARGE}
            onPress={() => props.setIsShowingSearch(true)}
          />
        </View>
      ) : (
        <Input
          value={props.searchQuery}
          editable={true}
          placeholder={'Search...'}
          InputWrapperStyle={styles.inputWrapperStyle}
          InputStyles={styles.inputStyles}
          placeholderTextColor={AppColors.common.subtitleText}
          paddingHorizontal={ComponentOptions.SPACES.SMALL}
          loading={props.isSearching}
          leftIcon={
            <Icon
              name={'ChevronLeft'}
              fill={AppColors.common.grey}
              onPress={() => props.setIsShowingSearch(false)}
            />
          }
          rightIcon={
            props.searchQuery?.length > 0 && (
              <Icon
                name={''}
                size={18}
                fill={AppColors.common.tertiary}
                onPress={() => {
                  !props.isSearching && props.setSearchQuery('')
                }}
              />
            )
          }
          onChangeText={props.setSearchQuery}
        />
      )}
    </>
  )
}

type YourClipsProps = {
  tab: TabProps
  isDeletingClip: boolean
  isFromClipCentral?: boolean
  onPressClip: (clip: Clip) => void
  onPressCreateClip: () => void
  onLongPressClip: (clipData: DeleteClipParams) => void
  onBackPress?: () => void
}

type NewClip = { clipId: number; isNew: boolean }

type ClipCreateItemProps = {
  theme: any
  onPress: () => void
}

const ClipCreateItem = (props: ClipCreateItemProps) => {
  const { theme, onPress } = props

  const { width } = Util.getWindowDimensions()

  const padding = ComponentOptions.SPACES.MEDIUM

  const clipContainerWidth =
    (width < Constants.BREAK_POINT
      ? width - padding
      : Constants.SCREEN_LAYOUT_DEFAULT_SIZES.RIGHT_PANEL) * 0.5

  const clipContainerHeight = clipContainerWidth * 1.3

  const paddings = Util.getPaddings(2, padding, 0)

  const clipOverlayContainerStyle = {
    width: clipContainerWidth - (paddings.paddingLeft + paddings.paddingRight),
    height:
      clipContainerHeight - (paddings.paddingTop + paddings.paddingBottom),
  }

  return (
    <View style={styles.clipCreateWrapperStyle}>
      <Tile
        onPress={onPress}
        borderWidth={1}
        height={clipOverlayContainerStyle.height}
        width={clipOverlayContainerStyle.width}
        backgroundColor={theme.tertiary}
        borderColor={AppColors.common.primary}
        shadowStyle={AppStyles.shadowStyle.tile}
        renderBottomElement={
          <Text body_small color={theme.textColors.title}>
            Create clip
          </Text>
        }
        renderTopLeftElement={{
          fill: AppColors.common.primary,
          name: 'Add',
          size: 24,
          stroke: 1,
        }}
      />
    </View>
  )
}

const YourClips = (props: YourClipsProps) => {
  const {
    tab,
    isDeletingClip,
    isFromClipCentral,
    onPressClip,
    onPressCreateClip,
    onLongPressClip,
    onBackPress,
  } = props

  const theme = useTheme()

  const [isVisibleDeleteClipDrawer, setIsVisibleDeleteClipDrawer] =
    useState<boolean>(false)
  const [deletingClip, setDeletingClip] = useState<DeleteClipParams>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [isShowingSearch, setIsShowingSearch] = useState<boolean>(false)

  const orgId = useActiveOrgId()
  const {
    data: items,
    isLoading: isLoading,
    hasNextPage,
    fetchNextPage,
  } = useFetchYourClips(orgId)

  const {
    data: serchedClips,
    isFetching: isSearching,
    hasNextPage: searchHasNextPage,
    fetchNextPage: fetchSearchNextPage,
    refetch,
  } = useFetchSearchClips(orgId, searchQuery)

  useEffect(() => {
    if (isShowingSearch) {
      const timeout = setTimeout(() => {
        refetch()
      }, 1000)
      return () => clearTimeout(timeout)
    }
  }, [searchQuery])

  const data = (items || []) as Clip[]

  const clips: Clip[] | NewClip[] = [
    { clipId: -1, isNew: true } as NewClip,
    ...data,
  ]
  return (
    <TabPanel key={`your_clips`} displayKey={tab.tabKey}>
      {isFromClipCentral && (
        <ClipCentralHeader
          isSearching={isSearching}
          searchQuery={searchQuery}
          isShowingSearch={isShowingSearch}
          setSearchQuery={setSearchQuery}
          onBackPress={onBackPress}
          setIsShowingSearch={setIsShowingSearch}
        />
      )}
      <FlatList
        data={
          isShowingSearch ? ((serchedClips || []) as Clip[]) : (clips as Clip[])
        }
        extraData={clips}
        numColumns={2}
        renderItem={({ item, index }) =>
          item?.isNew && !isLoading ? (
            <ClipCreateItem theme={theme} onPress={onPressCreateClip} />
          ) : (
            <ClipItem
              item={item}
              isLoading={
                isLoading ||
                isSearching ||
                (deletingClip?.clipId === item.clipId && isDeletingClip)
              }
              index={index}
              columns={2}
              onPressItem={(item: GalleryItem) => onPressClip(item as Clip)}
              onLongPressItem={(clipId) => {
                setDeletingClip({
                  clipId: clipId,
                  threadId: item.threadId,
                })
                setIsVisibleDeleteClipDrawer(true)
              }}
            />
          )
        }
        showsHorizontalScrollIndicator={false}
        keyExtractor={(item, index) => `sectionItem${item.recordKey}${index}`}
        onEndReached={() => {
          if (!isShowingSearch && hasNextPage) {
            fetchNextPage()
          } else if (isShowingSearch && searchHasNextPage) {
            fetchSearchNextPage()
          }
        }}
        onEndReachedThreshold={0.9}
      />
      <DeleteBottomDrawer
        isVisible={isVisibleDeleteClipDrawer}
        title={'Remove entire clip?'}
        subTitle={
          'If this clips is shared, collaborators can no longer view or edit this clips. It will be permanently deleted.'
        }
        onModalHide={() => setIsVisibleDeleteClipDrawer(false)}
        onPress={() => {
          onLongPressClip(deletingClip as DeleteClipParams)
          setIsVisibleDeleteClipDrawer(false)
        }}
      />
    </TabPanel>
  )
}

export default YourClips
