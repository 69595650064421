import { View, StyleSheet, TouchableOpacity } from 'react-native'
import {
  ComponentOptions,
  AppColors,
  Icon,
  Text,
} from '@gotradie/gt-components'

const styles = StyleSheet.create({
  actionButtonStyles: {
    width: ComponentOptions.SPACES.LARGEST,
    height: ComponentOptions.SPACES.LARGEST,
    borderRadius: ComponentOptions.SPACES.LARGEST,
    backgroundColor: AppColors.common.primary,
    justifyContent: 'center',
    alignItems: 'center',
  },
  actionButtonWarapper: {
    width: ComponentOptions.SPACES.EXTRA_LARGER,
    height: ComponentOptions.SPACES.EXTRA_LARGER,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  deviceErrorIndicatorStyles: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: ComponentOptions.SPACES.MEDIUM,
    height: ComponentOptions.SPACES.MEDIUM,
    borderRadius: ComponentOptions.SPACES.MEDIUM,
    backgroundColor: AppColors.common.secondery,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
})

type ActionButtonProps = {
  enabled: boolean
  deviceError: boolean
  actionDisabled: boolean
  enableIcon: string
  disableIcon: string
  color?: string
  size?: number
  toggleAction: () => void
  errorAction: () => void
}

const ActionButton = ({
  enabled,
  deviceError,
  actionDisabled,
  enableIcon,
  disableIcon,
  color,
  size,
  toggleAction,
  errorAction,
}: ActionButtonProps) => {
  return (
    <View style={styles.actionButtonWarapper}>
      {!!deviceError && (
        <TouchableOpacity
          style={styles.deviceErrorIndicatorStyles}
          onPress={errorAction}
        >
          <Text body_strong color={AppColors.common.white}>
            {'!'}
          </Text>
        </TouchableOpacity>
      )}
      <TouchableOpacity
        style={[
          styles.actionButtonStyles,
          {
            backgroundColor: enabled
              ? color || AppColors.common.primary
              : AppColors.common.subtitleText,
          },
        ]}
        disabled={actionDisabled}
        onPress={toggleAction}
      >
        <Icon
          name={enabled ? enableIcon : disableIcon}
          size={size || 30}
          fill={AppColors.common.white}
        />
      </TouchableOpacity>
    </View>
  )
}

export default ActionButton
