import { useInfiniteQuery, useQuery } from 'react-query'
import { Constants, getAxios, QueryKeys } from '../../common'
import { useActiveOrgId } from '../../context/UserContext'
import { Coordinates, Location } from '../../types/Common'
import {
  Clip,
  HighlightedEntity,
  Slide,
  TaggedEntityBusiness,
  TaggedEntityUser,
} from '../../types/Files'

const api = getAxios()

const fetchYourClips = async (businessId: string, pageParam = '') => {
  const pageSize = Constants.PAGE_SIZES.CLIPS_FETCH_BATCH_SIZE
  return await api.get(
    `/business/${businessId}/clips?page-size=${pageSize}&start-key=${pageParam}`
  )
}

const fetchTaggedClips = async (activeUserId: string, pageParam = '') => {
  const pageSize = Constants.PAGE_SIZES.CLIPS_FETCH_BATCH_SIZE
  return await api.get(
    `user-profile/${activeUserId}/tagged-clips?page-size=${pageSize}&start-key=${pageParam}`
  )
}

const fetchClipHighlights = async (businessId: string) => {
  return await api.get(`/business/${businessId}/clips/highlights`)
}

const fetchClip = async (clipId: string) => {
  return await api.get(`/clips/${clipId}`)
}

const fetchSearchClips = async (
  businessId: string,
  query: string,
  pageParam: string
) => {
  const pageSize = Constants.PAGE_SIZES.CLIPS_FETCH_BATCH_SIZE
  return await api.get(
    `/clips/search?query=${query}&page-size=${pageSize}&page=${pageParam || 0}`
  )
}

export const useFetchYourClips = (businessId: string) => {
  return useInfiniteQuery(
    [QueryKeys.YOUR_CLIPS, businessId, useActiveOrgId()],
    ({ pageParam }) => fetchYourClips(businessId, pageParam),
    {
      enabled: true,
      getNextPageParam: (lastPage) => {
        const startKey = lastPage?.data?.metadata?.['start-key']
        if (startKey) {
          return startKey
        } else {
          return undefined
        }
      },
      select: (data: any) => {
        const clipsSets = data?.pages?.map((page: any) => page?.data?.clips)
        if (!clipsSets) {
          return []
        } else {
          return clipsSets.flat().map((clip: any) => dtoToClip(clip))
        }
      },
      cacheTime: Constants.DURATION.HOUR,
    }
  )
}

export const useFetchTaggedClips = (activeUserId: string) => {
  return useInfiniteQuery(
    [QueryKeys.TAGGED_CLIPS, activeUserId, useActiveOrgId()],
    ({ pageParam }) => fetchTaggedClips(activeUserId, pageParam),
    {
      enabled: true,
      getNextPageParam: (lastPage) => {
        const startKey = lastPage?.data?.metadata?.start_key
        if (startKey) {
          return startKey
        } else {
          return undefined
        }
      },
      cacheTime: Constants.DURATION.HOUR,
    }
  )
}

export const useFetchClipHighlights = (businessId: string, enabled = true) => {
  return useQuery(
    [QueryKeys.CLIP_HIGHLIGHTS, businessId, useActiveOrgId()],
    () => fetchClipHighlights(businessId),
    {
      enabled: enabled,
      initialData: undefined,
      select: (data) => {
        const clipHighlights = data?.data.clips
        if (clipHighlights) {
          return clipHighlights.map((clip: any) => dtoToClip(clip)).sort()
        } else {
          return []
        }
      },
    }
  )
}

export const useFetchClip = (
  clipId: string,
  enabled = true,
  onError?: (er: any) => void
) => {
  return useQuery(
    [QueryKeys.CLIP, clipId, useActiveOrgId()],
    () => fetchClip(clipId),
    {
      enabled,
      onError: onError,
      initialData: undefined,
      select: (data) => {
        const clip = data?.data.clip
        if (clip) {
          return clip.clipId ? clip : dtoToClip(clip) //if its Primmed data, just return it without converting
        } else {
          return null
        }
      },
    }
  )
}

export const useFetchSearchClips = (businessId: string, query: string) => {
  return useInfiniteQuery(
    [QueryKeys.SEARCH_CLIPS, query, useActiveOrgId()],
    ({ pageParam }) => fetchSearchClips(businessId, query, pageParam),
    {
      enabled: false,
      getNextPageParam: (lastPage) => {
        const startKey = lastPage?.data?.metadata?.start_key
        if (startKey) {
          return startKey
        } else {
          return undefined
        }
      },
      select: (data: any) => {
        const clipsSets = data?.pages?.map((page: any) => page?.data?.clips)
        if (!clipsSets) {
          return []
        } else {
          return clipsSets.flat().map((clip: any) => dtoToClip(clip))
        }
      },
      cacheTime: Constants.DURATION.MINUTE,
    }
  )
}

function dtoToClip(clipResponse: any): Clip {
  const clip: Clip = {} as Clip
  clip.sequence = clipResponse['sequence'] || 0
  clip.clipId = clipResponse['clip-id']
  clip.clipDescription = clipResponse['clip-description'] || ''
  clip.clipTitle = clipResponse['clip-title'] || ''
  clip.coverImage = clipResponse['cover-image']
  clip.businessId = clipResponse['business-id']
  clip.businessLogo = clipResponse['business-logo']
  clip.businessName = clipResponse['business-name']
  clip.postedBy = clipResponse['posted-by']
  clip.threadId = clipResponse['thread-id'] || null
  clip.gtCreatedTime = clipResponse['gt-created-time']
  clip.businessHandle = clipResponse['business-handle']
  clip.clipUrl = `${process.env.BUSINESS_PROFILE_URL}/${clipResponse['business-handle']}/clips/${clipResponse['clip-id']}`
  clip.taggedServices = clipResponse['tagged-services'] || []
  clip.taggedEntities = {}
  clip.admins = clipResponse['admins'] || []
  ;(clip.clipPermissions = clipResponse['clip-permission-model'] || {}),
    (clip.highlightedEntities = {})
  clip.slides = []

  if (clipResponse['highlights-metadata']) {
    for (const key of Object.keys(clipResponse['highlights-metadata'])) {
      const value = clipResponse['highlights-metadata']
      clip.highlightedEntities[key] = {
        entityType: value['entity-type'],
      } as HighlightedEntity
    }
  }

  if (clipResponse['location']) {
    const locationResponse = clipResponse['location']
    clip.location = {
      streetAddress: locationResponse['street-address'],
      address: locationResponse['address'],
      streetNumber: locationResponse['street-number'],
      coordinates: locationResponse['coorfinates']
        ? ({
            latitude: locationResponse['coorfinates']['latitude'],
            longitude: locationResponse['coorfinates']['longitude'],
          } as Coordinates)
        : null,
      name: locationResponse['name'],
      suburb: locationResponse['suburb'],
      state: locationResponse['state'],
      postalCode: locationResponse['postal-code'],
      subPremise: locationResponse['sub-premise'],
      route: locationResponse['route'],
    } as Location
  }
  if (clipResponse['slides']) {
    const slides = clipResponse['slides']
    for (const slide of slides) {
      clip.slides.push({
        ...slide, // These keys used inside storybook clip preview
        slideSequence: slide['slide-sequence'],
        slideId: slide['slide-id'],
        thumbnailKey: slide['thumbnail-key'],
        slideKey: slide['slide-key'],
        slideType: slide['slide-type'],
        clipId: slide['clip-id'],
        businessLogo: slide?.['business-logo'],
        businessName: slide?.['business-name'],
        url: slide?.['url'],
        gtCreatedTime: slide?.['gt-created-time'],
        isEmptySlide:
          slide?.['slide-type'] === 'empty-clip-slide' ? true : false,
      } as Slide)
    }
  }
  if (clipResponse['tagged-entities']) {
    for (const key of Object.keys(clipResponse['tagged-entities'])) {
      const value = clipResponse['tagged-entities'][key]
      if (!!value['business-id']) {
        clip.taggedEntities[key] = {
          phoneNumber: value['phone-number'],
          profilePic: value['profile-pic'],
          userId: value['user-id'],
          effectiveUserId: value['effective-user-id'],
          businessId: value['business-id'],
          firstName: value['first-name'],
          email: value['email'],
          lastName: value['last-name'],
        } as TaggedEntityUser
      } else {
        clip.taggedEntities[key] = {
          businessName: value['business-name'],
          handle: value['handle'],
          businessId: value['business-id'],
          businessLogo: value['business-logo'],
        } as TaggedEntityBusiness
      }
    }
  }
  return clip
}
