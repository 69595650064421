import { View, StyleSheet, ActivityIndicator } from 'react-native'
import {
  ComponentOptions,
  AppColors,
  Button,
  Spacer,
  Icon,
  Text,
} from '@gotradie/gt-components'
import { Constants, Util } from '../../common'
import LocalStreamVideo from './LocalStreamVideo'
import Avatar from '../../components/Avatar'
import { Profile } from '../../types/User'
import ActionButton from './ActionButton'
import { CallState } from '../../types/Stream'

const styles = StyleSheet.create({
  lobbyWrapper: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeIconWrapper: {
    position: 'relative',
    width: '95%',
  },
  participantViewWrapper: {
    width: 'auto',
    height: 'calc(100% - 250px)',
    borderColor: AppColors.common.concrete,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  lobbyActionButtonsWrapper: {
    flexDirection: 'row',
    flex: 1,
    height: 'fit-content',
    marginTop: ComponentOptions.SPACES.MEDIUM,
  },
})

type CallLobbyProps = {
  profile?: Profile
  isJoiningCall: boolean
  deviceError: { mic: null | any; camera: null | any }
  myMicEnabled: boolean
  myCameraEnabled: boolean
  myMediaStream: MediaStream | undefined
  callState: CallState
  toggleMic: () => void
  toggleVideo: () => void
  startCall: () => void
  leaveCall: () => void
}

const CallLobby = ({
  profile,
  isJoiningCall,
  deviceError,
  myMicEnabled,
  myCameraEnabled,
  myMediaStream,
  callState,
  toggleMic,
  toggleVideo,
  startCall,
  leaveCall,
}: CallLobbyProps) => {
  if (isJoiningCall) {
    return (
      <View style={styles.lobbyWrapper}>
        <Text h3 color={AppColors.common.primary}>
          {'Joining...'}
        </Text>
        <Spacer size={ComponentOptions.AVATAR_SIZE.MEDIUM} />
        <Avatar
          s3Key={profile?.profilePic || ''}
          imageFetchType={Constants.IMAGE_FETCH_TYPE.PROFILE}
          avatarProps={{
            size: ComponentOptions.AVATAR_SIZE.LARGE,
            altText: Util.getAcronym(profile?.name || ''),
            uniqueId: profile?.effectiveUserId,
            hasBorder: true,
            borderColor: AppColors.common.primary,
          }}
        />
        <Spacer size={ComponentOptions.AVATAR_SIZE.MEDIUM} />
        <ActivityIndicator size="large" color={AppColors.common.primary} />
      </View>
    )
  }

  return (
    <View style={styles.lobbyWrapper}>
      <Spacer size={ComponentOptions.SPACES.MEDIUM} />
      <View style={styles.closeIconWrapper}>
        <Icon
          size={ComponentOptions.SPACES.MEDIUM}
          name={'Close'}
          fill={AppColors.common.secondery}
          wrapperStyles={{
            position: 'absolute',
            top: ComponentOptions.SPACES.MEDIUM,
          }}
          onPress={leaveCall}
        />
      </View>
      <Text h3 color={AppColors.common.primary}>
        {'Before Joining'}
      </Text>
      <Text p color={AppColors.common.subtitleText}>
        {'Setup your audio & video'}
      </Text>
      <Spacer size={ComponentOptions.SPACES.LARGE} />
      <View style={styles.participantViewWrapper}>
        {myMediaStream ? (
          <LocalStreamVideo
            elementId={'localVideo'}
            mediaStream={myMediaStream}
          />
        ) : (
          <Avatar
            s3Key={profile?.profilePic || ''}
            imageFetchType={Constants.IMAGE_FETCH_TYPE.PROFILE}
            avatarProps={{
              size: ComponentOptions.AVATAR_SIZE.LARGE,
              altText: Util.getAcronym(profile?.firstName || ''),
              uniqueId: profile?.effectiveUserId,
              hasBorder: true,
              borderColor: AppColors.common.primary,
            }}
          />
        )}
      </View>
      <View style={styles.lobbyActionButtonsWrapper}>
        <ActionButton
          enabled={myMicEnabled}
          deviceError={!!deviceError.mic}
          actionDisabled={false}
          enableIcon={'DetailedMicOn'}
          disableIcon={'DetailedMicOff'}
          toggleAction={toggleMic}
          errorAction={() => Util.showWarningMessage('Device not found')}
        />
        <Spacer isVertical size={ComponentOptions.SPACES.LARGE} />
        <ActionButton
          enabled={myCameraEnabled}
          deviceError={!!deviceError.camera}
          actionDisabled={false}
          enableIcon={'DetailedVideoOn'}
          disableIcon={'DetailedVideoOff'}
          toggleAction={toggleVideo}
          errorAction={() => Util.showWarningMessage('device not found')}
        />
      </View>
      <Spacer size={ComponentOptions.SPACES.LARGE} />
      <Button
        title={callState?.isCreator ? 'Start Call' : 'Join Call'}
        loading={isJoiningCall}
        onPress={startCall}
        width={ComponentOptions.SPACES.LARGEST * 4}
      />
      <Spacer size={ComponentOptions.SPACES.LARGE} />
    </View>
  )
}

export default CallLobby
